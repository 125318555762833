// Copyright (C) 2021-2022 Intel Corporation
//
// SPDX-License-Identifier: MIT

import React from 'react';
import { Row } from 'antd/lib/grid';
import Text from 'antd/lib/typography/Text';
import Collapse from 'antd/lib/collapse';

import ItemAttribute from './object-item-attribute';

interface Props {
    readonly: boolean;
    collapsed: boolean;
    attributes: any[];
    values: Record<number, string>;
    changeAttribute(attrID: number, value: string): void;
    collapse(): void;
    states: any;
}

export function attrValuesAreEqual(next: Record<number, string>, prev: Record<number, string>): boolean {
    const prevKeys = Object.keys(prev);
    const nextKeys = Object.keys(next);

    return (
        nextKeys.length === prevKeys.length &&
        nextKeys.map((key: string): boolean => prev[+key] === next[+key]).every((value: boolean) => value)
    );
}

function attrAreTheSame(prevProps: Props, nextProps: Props): boolean {
    return (
        nextProps.readonly === prevProps.readonly &&
        nextProps.collapsed === prevProps.collapsed &&
        // nextProps.attributes === prevProps.attributes &&
        attrValuesAreEqual(nextProps.values, prevProps.values)
    );
}

function ItemAttributesComponent(props: Props): JSX.Element {
    const { collapsed, attributes, values, readonly, changeAttribute, collapse, states } = props;
    let attributeUuid: any[] = [];

    let newObjects = states.filter((el: any) => {
        return el.outside === false;
    });

    // let attributeValues: [] = newObjects.map((val: any) => val.attributes); // commented by raju
    // attributeValues.forEach((attr: any) => {
    //     // let filterValue: any;
    //     let objectLength: any = Object.keys(attr).pop();
    //     // for (let value in attr) {
    //     //     filterValue = attr[objectLength];
    //     // }
    //     attributeUuid.push(attr[objectLength]);
    // });
    const allAttributes: any[] = [];
    newObjects?.forEach((val: any) => {
        let filterValue: any[] = [];
        let test: any;
        let labelAttribut_value = val?.label.attributes;
        let labelAttributes = val?.attributes;
        let Clientid = val?.clientID;
        labelAttribut_value.forEach((val: any) => {
            for (const key in labelAttributes) {
                if (labelAttributes.hasOwnProperty(key)) {
                    if (Number(key) === val.id) {
                        test = { [val.name]: labelAttributes[key], clientId: Clientid };
                    }
                }
            }
            filterValue.push(test);
        });
        allAttributes.push(filterValue);
    });
    let removeArray = allAttributes.flat();
    removeArray.map(function (a) {
        if (a.UUID !== undefined) {
            attributeUuid.push(a.UUID);
        }
    });
    return (
        <Row>
            <Collapse
                className='cvat-objects-sidebar-state-item-collapse'
                activeKey={collapsed ? [] : ['details']}
                onChange={collapse}
            >
                <Collapse.Panel
                    header={
                        <Text style={{ fontSize: 10 }} type='secondary'>
                            DETAILS
                        </Text>
                    }
                    key='details'
                >
                    {attributes.map(
                        (attribute: any): JSX.Element => (
                            <Row
                                key={attribute.id}
                                align='middle'
                                justify='start'
                                className='cvat-object-item-attribute-wrapper'
                            >
                                <ItemAttribute
                                    readonly={readonly}
                                    attrValue={values[attribute.id]}
                                    attrInputType={attribute.inputType}
                                    attrName={attribute.name}
                                    attrID={attribute.id}
                                    attrValues={attribute.values}
                                    changeAttribute={changeAttribute}
                                    UUIDAttach={attributeUuid}
                                />
                            </Row>
                        ),
                    )}
                </Collapse.Panel>
            </Collapse>
        </Row>
    );
}

export default React.memo(ItemAttributesComponent, attrAreTheSame);
