// Copyright (C) 2020-2022 Intel Corporation
//
// SPDX-License-Identifier: MIT

import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
    LeftOutlined, RightOutlined, EyeInvisibleFilled, EyeOutlined,
    CheckCircleFilled, CheckCircleOutlined,
} from '@ant-design/icons';
import Alert from 'antd/lib/alert';
import { Row, Col } from 'antd/lib/grid';

import { changeFrameAsync } from 'actions/annotation-actions';
import { commentIssueAsync, reopenIssueAsync, resolveIssueAsync, reviewActions } from 'actions/review-actions';
import CVATTooltip from 'components/common/cvat-tooltip';
import { CombinedState } from 'reducers';
import IssueDialog from 'components/annotation-page/review/issue-dialog';
import HiddenIssueLabel from 'components/annotation-page/review/hidden-issue-label';
export default function LabelsListComponent(): JSX.Element {
    const [visible, setVisible] = useState(false);
    const [expandedIssue, setExpandedIssue] = useState<number | null>(null);
    const issueFetching = useSelector((state: CombinedState): number | null => state.review.fetching.issueId);
    const issueLabels: JSX.Element[] = [];
    const issueDialogs: JSX.Element[] = [];
    const dispatch = useDispatch();
    const frame = useSelector((state: CombinedState): number => state.annotation.player.frame.number);
    const frameIssues = useSelector((state: CombinedState): any[] => state.review.frameIssues);
    const issues = useSelector((state: CombinedState): any[] => state.review.issues);
    const issuesHidden = useSelector((state: CombinedState): any => state.review.issuesHidden);
    const issuesResolvedHidden = useSelector((state: CombinedState): any => state.review.issuesResolvedHidden);
    const frames = issues.map((issue: any): number => issue.frame).sort((a: number, b: number) => +a - +b);
    const nearestLeft = frames.filter((_frame: number): boolean => _frame < frame).reverse()[0];
    const dynamicLeftProps: any = Number.isInteger(nearestLeft) ?
        {
            onClick: () => dispatch(changeFrameAsync(nearestLeft)),
        } :
        {
            style: {
                pointerEvents: 'none',
                opacity: 0.5,
            },
        };

    const nearestRight = frames.filter((_frame: number): boolean => _frame > frame)[0];
    const dynamicRightProps: any = Number.isInteger(nearestRight) ?
        {
            onClick: () => dispatch(changeFrameAsync(nearestRight)),
        } :
        {
            style: {
                pointerEvents: 'none',
                opacity: 0.5,
            },
        };
        for (const issue of frameIssues) {
            if (issuesHidden) break;
            const issueResolved = issue.resolved;
            if (issuesResolvedHidden && issueResolved) continue;
            const offset = 15;
            const translated = issue.position.map((coord: number): number => coord + 320);
            const minX = Math.min(...translated.filter((_: number, idx: number): boolean => idx % 2 === 0)) + offset;
            const minY = Math.min(...translated.filter((_: number, idx: number): boolean => idx % 2 !== 0)) + offset;
            const { id } = issue;
            const highlight = (): void => {
                const element = window.document.getElementById(`cvat_canvas_issue_region_${id}`);
                if (element) {
                    element.style.display = 'block';
                }
            };
            const blur = (): void => {
                if (issueResolved) {
                    const element = window.document.getElementById(`cvat_canvas_issue_region_${id}`);
                    if (element) {
                        element.style.display = 'none';
                    }
                }
            };

            if (expandedIssue === id) {
                issueDialogs.push(
                    <IssueDialog
                        key={issue.id}
                        issue={issue}
                        top={minY}
                        left={minX}
                        angle={-0}
                        scale={1 / 1}
                        isFetching={issueFetching !== null}
                        resolved={issueResolved}
                        highlight={highlight}
                        blur={blur}
                        collapse={() => {
                            setExpandedIssue(null);
                        }}
                        resolve={() => {
                            dispatch(resolveIssueAsync(issue.id));
                            setExpandedIssue(null);
                        }}
                        reopen={() => {
                            dispatch(reopenIssueAsync(issue.id));
                        }}
                        comment={(message: string) => {
                            dispatch(commentIssueAsync(issue.id, message));
                        }}
                    />,
                );
            } else {
                issueLabels.push(
                    <HiddenIssueLabel
                        key={issue.id}
                        issue={issue}
                        top={minY}
                        left={minX}
                        angle={-0}
                        scale={1 / 1}
                        resolved={issueResolved}
                        highlight={highlight}
                        blur={blur}
                        onClick={() => {
                            setExpandedIssue(id);
                        }}
                    />,
                );
            }
        }
        const handleShowIssues = (val:number) => {
            setExpandedIssue(val);
            setVisible(true);
          };
    return (
        <>
            <div className='cvat-objects-sidebar-issues-list-header'>
                <Row justify='start' align='middle'>
                    <Col>
                        <CVATTooltip title='Find the previous frame with issues'>
                          <LeftOutlined className='cvat-issues-sidebar-frame' {...dynamicLeftProps} />
                        </CVATTooltip>
                    </Col>
                    <Col offset={1}>
                        <CVATTooltip title='Find the next frame with issues'>
                          <RightOutlined className='cvat-issues-sidebar-frame' {...dynamicRightProps} />
                        </CVATTooltip>
                    </Col>
                    <Col offset={2}>
                        <CVATTooltip title='Show/hide all issues'>
                            {issuesHidden ? (
                                <EyeInvisibleFilled
                                    className='cvat-issues-sidebar-hidden-issues'
                                    onClick={() => dispatch(reviewActions.switchIssuesHiddenFlag(false))}
                                />
                            ) : (
                                <EyeOutlined
                                    className='cvat-issues-sidebar-shown-issues'
                                    onClick={() => dispatch(reviewActions.switchIssuesHiddenFlag(true))}
                                />
                            )}
                        </CVATTooltip>
                    </Col>
                    <Col offset={2}>
                        <CVATTooltip title='Show/hide resolved issues'>
                            { issuesResolvedHidden ? (
                                <CheckCircleFilled
                                    className='cvat-issues-sidebar-hidden-resolved-status'
                                    onClick={() => dispatch(reviewActions.switchIssuesHiddenResolvedFlag(false))}
                                />
                            ) : (
                                <CheckCircleOutlined
                                    className='cvat-issues-sidebar-hidden-resolved-status'
                                    onClick={() => dispatch(reviewActions.switchIssuesHiddenResolvedFlag(true))}
                                />

                            )}
                        </CVATTooltip>
                    </Col>
                </Row>
            </div>
            <div className='cvat-objects-sidebar-issues-list'>
                {frameIssues.map(
                    (frameIssue: any): JSX.Element => (
                        <div
                            key={frameIssue.id}
                            id={`cvat-objects-sidebar-issue-item-${frameIssue.id}`}
                            className='cvat-objects-sidebar-issue-item'
                            onMouseEnter={() => {
                                const element = window.document.getElementById(
                                    `cvat_canvas_issue_region_${frameIssue.id}`,
                                );
                                if (element) {
                                    element.setAttribute('fill', 'url(#cvat_issue_region_pattern_2)');
                                }
                            }}
                            onMouseLeave={() => {
                                const element = window.document.getElementById(
                                    `cvat_canvas_issue_region_${frameIssue.id}`,
                                );
                                if (element) {
                                    element.setAttribute('fill', 'url(#cvat_issue_region_pattern_1)');
                                }
                            }}
                        >
                            {frameIssue.resolved ? (
                                <Alert message='Resolved' type='success' showIcon />
                            ) : (
                                <Alert message='Opened' type='warning' showIcon onClick={() => handleShowIssues(frameIssue.id)} />
                            )}
                        </div>
                    ),
                )}
            </div>
            {visible && <div>{issueDialogs}</div>}
        </>
    );
}
