// Copyright (C) 2020-2022 Intel Corporation
// Copyright (C) 2023 CVAT.ai Corporation
//
// SPDX-License-Identifier: MIT

import './styles.scss';
import React from 'react';
import Layout from 'antd/lib/layout';
import { useSelector } from 'react-redux';
import { CombinedState } from 'reducers';

import ControlsSideBarContainer from 'containers/annotation-page/review-workspace/controls-side-bar/controls-side-bar';
import CanvasLayout from 'components/annotation-page/canvas/grid-layout/canvas-layout';
import ObjectSideBarComponent from 'components/annotation-page/standard-workspace/objects-side-bar/objects-side-bar';
import ObjectsListContainer from 'containers/annotation-page/standard-workspace/objects-side-bar/objects-list';
import CanvasContextMenuContainer from 'containers/annotation-page/canvas/canvas-context-menu';
import IssueAggregatorComponent from 'components/annotation-page/review/issues-aggregator';
//added raju
import IssueAggregator3dComponent from 'components/annotation-page/review/issues-aggregator3d';
export default function ReviewWorkspaceComponent(): JSX.Element {
    const job = useSelector((state: CombinedState) => state.annotation.job);

    return (
        <Layout hasSider className='cvat-review-workspace'>
            <ControlsSideBarContainer />
            <CanvasLayout type={job.dimension} />
            <ObjectSideBarComponent objectsList={<ObjectsListContainer readonly />} />
            <CanvasContextMenuContainer readonly />
            {job.dimension === '3d' && <IssueAggregator3dComponent />}
            {job.dimension === '2d' && <IssueAggregatorComponent />}
        </Layout>
    );
}
