// Copyright (C) 2020-2022 Intel Corporation
// Copyright (C) 2023 CVAT.ai Corporation
//
// SPDX-License-Identifier: MIT

import React from 'react';
import { Col } from 'antd/lib/grid';
import Icon,{BorderOutlined} from '@ant-design/icons';
import Select from 'antd/lib/select';
import Button from 'antd/lib/button';
import { useSelector } from 'react-redux';

import { FilterIcon, FullscreenIcon, InfoIcon, DeleteIcon, DeleteAllIcon } from 'icons';
import { CombinedState, DimensionType, Workspace } from 'reducers';
import CVATTooltip from 'components/common/cvat-tooltip';
import Modal from 'antd/lib/modal';
import Text from 'antd/lib/typography/Text';
import InputNumber from 'antd/lib/input-number';
import Collapse from 'antd/lib/collapse';
import Checkbox from 'antd/lib/checkbox';
interface Props {
    workspace: Workspace;
    showStatistics(): void;
    showFrames():void;
    showFilters(): void;
    changeWorkspace(workspace: Workspace): void;
    jobInstance: any;
    // code change by raju
    // onClickMenu(params: any): void;
    ObjectActivatID: any;
    stopFrame: number;
    removeAnnotations(startnumber: number, endnumber: number, delTrackKeyframesOnly: boolean): void;
}
export enum Actions {
    REMOVE_ANNO = 'remove_anno',
}
function RightGroup(props: Props): JSX.Element {
    const {
        showStatistics,
        showFrames,
        changeWorkspace,
        workspace,
        jobInstance,
        showFilters,
        ObjectActivatID,
        // onClickMenu,
        stopFrame,
        removeAnnotations,
    } = props;

    const filters = useSelector((state: CombinedState) => state.annotation.annotations.filters);
    // added by raju
    function onClickdelete(): void {
        const evt = new KeyboardEvent('keydown', { keyCode: 46, which: 46 });

        document.dispatchEvent(evt);
    }
    function DeleteObject(): void {
        if (ObjectActivatID.activatedStateID !== null) {
            Modal.confirm({
                title: 'Annotation will be deleted',

                content: 'Do you want to delete the selected annotation from the task? ',

                className: 'cvat-modal-confirm-delete-annotation',

                onOk: () => {
                    onClickdelete();
                },

                okButtonProps: {
                    type: 'primary',

                    danger: true,
                },

                okText: 'Delete',
            });
        } else {
            Modal.info({
                title: 'Annotation will not be remove',

                content: 'Please Select one annotation Object',

                className: 'cvat-modal-confirm-delete-annotation',

                okButtonProps: {
                    type: 'primary',

                    danger: true,
                },
            });
        }
    }
    // function DeleteAllObject(key: any): void {
    //     if (key === Actions.REMOVE_ANNO) {
    //         Modal.confirm({
    //             title: 'All the annotations will be deleted',

    //             content: `You are going to delete  all annotations from the task. Do you want to
    //                     continue?`,

    //             className: 'cvat-modal-confirm-delete-annotation',

    //             onOk: () => {
    //                 onClickMenu('remove_anno');
    //             },

    //             okButtonProps: {
    //                 type: 'primary',

    //                 danger: true,
    //             },

    //             okText: 'Delete',
    //         });
    //     }
    // }
    function DeleteAllObject(key: any): void {
        if (key === Actions.REMOVE_ANNO) {
            let removeFrom: number;
            let removeUpTo: number;
            let removeOnlyKeyframes = false;
            const { Panel } = Collapse;
            Modal.confirm({
                title: 'Remove Annotations',
                content: (
                    <div>
                        <Text>You are going to remove the annotations from the client. </Text>
                        <Text>It will stay on the server till you save the job. Continue?</Text>
                        <br />
                        <br />
                        <Collapse bordered={false}>
                            <Panel header={<Text>Select Range</Text>} key={1}>
                                <Text>From: </Text>
                                <InputNumber
                                    min={0}
                                    max={stopFrame}
                                    onChange={(value) => {
                                        removeFrom = value;
                                    }}
                                />
                                <Text> To: </Text>
                                <InputNumber
                                    min={0}
                                    max={stopFrame}
                                    onChange={(value) => {
                                        removeUpTo = value;
                                    }}
                                />
                                <CVATTooltip title='Applicable only for annotations in range'>
                                    <br />
                                    <br />
                                    <Checkbox
                                        onChange={(check) => {
                                            removeOnlyKeyframes = check.target.checked;
                                        }}
                                    >
                                        Delete only keyframes for tracks
                                    </Checkbox>
                                </CVATTooltip>
                            </Panel>
                        </Collapse>
                    </div>
                ),
                className: 'cvat-modal-confirm-remove-annotation',
                onOk: () => {
                    removeAnnotations(removeFrom, removeUpTo, removeOnlyKeyframes);
                },
                okButtonProps: {
                    type: 'primary',
                    danger: true,
                },
                okText: 'Delete',
            });
        }
    }
    return (
        <Col className='cvat-annotation-header-right-group'>
             <Button                                                      //added by Ganesh
                type='link'
                className='cvat-annotation-header-frames-button cvat-annotation-header-button'
                onClick={showFrames}
            >
                <BorderOutlined />
                Frames
            </Button>
            <Button
                type='link'
                className='cvat-annotation-header-fullscreen-button cvat-annotation-header-button'
                onClick={(): void => {
                    if (window.document.fullscreenEnabled) {
                        if (window.document.fullscreenElement) {
                            window.document.exitFullscreen();
                        } else {
                            window.document.documentElement.requestFullscreen();
                        }
                    }
                }}
            >
                <Icon component={FullscreenIcon} />
                Fullscreen
            </Button>
            <Button
                type='link'
                className='cvat-annotation-header-info-button cvat-annotation-header-button'
                onClick={showStatistics}
            >
                <Icon component={InfoIcon} />
                {/* Info */}
            </Button>
            <CVATTooltip overlay='Delete selected annotation'>
                <Button
                    style={{
                        pointerEvents: ObjectActivatID?.activatedStateID ? 'initial' : 'none',
                        opacity: ObjectActivatID?.activatedStateID ? 1 : 0.5,
                    }}
                    className='cvat-annotation-header-button cvat-annotation-header-left-align'
                    type='link'
                    onClick={() => DeleteObject()}
                >
                    <Icon component={DeleteIcon} />
                    <span> Delete </span>
                </Button>
            </CVATTooltip>
            <Button
                type='link'
                className={`cvat-annotation-header-filters-button cvat-annotation-header-button ${
                    filters.length ? 'filters-armed' : ''
                }`}
                onClick={showFilters}
            >
                <Icon component={FilterIcon} />
                Filters
            </Button>
            <CVATTooltip overlay='Delete all annotations'>
                <Button
                    style={{
                        pointerEvents: ObjectActivatID?.activatedStateID ? 'initial' : 'none',

                        opacity: ObjectActivatID?.activatedStateID ? 1 : 0.5,
                    }}
                    className='cvat-annotation-header-button cvat-annotation-header-left-align'
                    type='link'
                    onClick={() => DeleteAllObject('remove_anno')}
                >
                    <Icon component={DeleteAllIcon} />
                    Delete All
                </Button>
                {/* <Button
                    style={{
                        pointerEvents: ObjectActivatID?.activatedStateID ? 'initial' : 'none',

                        opacity: ObjectActivatID?.activatedStateID ? 1 : 0.5,
                    }}
                    className='cvat-annotation-header-button cvat-annotation-header-left-align'
                    type='link'
                    onClick={() => DeleteAllObject('remove_anno')}
                >
                    <Icon component={DeleteAllIcon} />

                    <span> DeleteAll </span>
                </Button> */}
            </CVATTooltip>
            <div>
                <Select
                    dropdownClassName='cvat-workspace-selector-dropdown'
                    className='cvat-workspace-selector'
                    onChange={changeWorkspace}
                    value={workspace}
                >
                    {Object.values(Workspace).map((ws) => {
                        if (jobInstance.dimension === DimensionType.DIM_3D) {
                            if (ws === Workspace.STANDARD) {
                                return null;
                            }
                            return (
                                // <Select.Option disabled={ws !== Workspace.STANDARD3D} key={ws} value={ws}>
                                <Select.Option key={ws} value={ws}>
                                    {ws}
                                </Select.Option>
                            );
                        }
                        if (ws !== Workspace.STANDARD3D) {
                            return (
                                <Select.Option key={ws} value={ws}>
                                    {ws}
                                </Select.Option>
                            );
                        }
                        return null;
                    })}
                </Select>
            </div>
        </Col>
    );
}

export default React.memo(RightGroup);
