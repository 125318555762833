// Copyright (C) 2020-2022 Intel Corporation
//
// SPDX-License-Identifier: MIT

import './styles.scss';
import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { CombinedState } from 'reducers';
import { Canvas } from 'cvat-canvas/src/typescript/canvas';

import { Canvas3d } from 'cvat-canvas3d/src/typescript/canvas3d';
import CreateIssueDialog from './create-issue-dialog';
export default function IssueAggregator3dComponent(): JSX.Element | null {
    const dispatch = useDispatch();
    const [expandedIssue, setExpandedIssue] = useState<number | null>(null);
    const states = useSelector((state: CombinedState) => state.annotation.annotations.states);
    const activatedStateID = useSelector((state: CombinedState) => state.annotation.annotations.activatedStateID);
    const frameIssues = useSelector((state: CombinedState): any[] => state.review.frameIssues);
    const issuesHidden = useSelector((state: CombinedState): boolean => state.review.issuesHidden);
    const issuesResolvedHidden = useSelector((state: CombinedState): boolean => state.review.issuesResolvedHidden);
    const canvasInstance = useSelector((state: CombinedState) => state.annotation.canvas.instance);
    const canvasIsReady = useSelector((state: CombinedState): boolean => state.annotation.canvas.ready);
    const newIssuePosition = useSelector((state: CombinedState): number[] | null => state.review.newIssuePosition);
    const [geometry, setGeometry] = useState<Canvas['geometry'] | Canvas3d['geometry']>(null);
    let ObjectId: number;
    if (Number.isInteger(activatedStateID)) {
        const activatedState = states.filter((state: any) => state.clientID === activatedStateID)[0];
        ObjectId = activatedState?.serverID;
    }
    useEffect(() => {
        if (canvasInstance instanceof Canvas3d) {
            const { geometry: updatedGeometry } = canvasInstance;
            setGeometry(updatedGeometry);
        }

        return () => {};
    }, [canvasInstance]);

    useEffect(() => {
        if (canvasInstance instanceof Canvas3d) {
            type IssueRegionSet = Record<number, { hidden: boolean; points: number[] }>;
            const regions = !issuesHidden
                ? frameIssues
                      .filter((_issue: any) => !issuesResolvedHidden || !_issue.resolved)
                      .reduce((acc: IssueRegionSet, issue: any): IssueRegionSet => {
                          acc[issue.id] = {
                              points: issue.position,
                              hidden: issue.resolved,
                          };
                          return acc;
                      }, {})
                : {};

            if (newIssuePosition) {
                // regions[0] is always empty because key is an id of an issue (<0, >0 are possible)
                regions[0] = {
                    points: newIssuePosition,
                    hidden: false,
                };
            }

            canvasInstance.setupIssueRegions(regions);

            if (newIssuePosition) {
                setExpandedIssue(null);
                const element = window.document.getElementById('cvat_canvas_issue_region_0');
                if (element) {
                    element.style.display = 'block';
                }
            }
        }
    }, [newIssuePosition, frameIssues, issuesResolvedHidden, issuesHidden, canvasInstance]);
    if (!(canvasInstance instanceof Canvas3d) || !canvasIsReady || !geometry) {
        return null;
    }
    const translated = newIssuePosition ? newIssuePosition.map((coord: number): number => coord + 320) : [];
    const createLeft = translated.length
        ? Math.max(...translated.filter((_: number, idx: number): boolean => idx % 2 === 0))
        : null;
    const createTop = translated.length
        ? Math.min(...translated.filter((_: number, idx: number): boolean => idx % 2 !== 0))
        : null;
    return (
        <>
            {createLeft !== null && createTop !== null ? (
                <CreateIssueDialog
                    top={createTop}
                    left={createLeft}
                    angle={-geometry.angle}
                    scale={1 / geometry.scale}
                    object_id={ObjectId}
                />
            ) : null}
        </>
    );
}
