// Copyright (C) 2020-2022 Intel Corporation
//
// SPDX-License-Identifier: MIT

import React from 'react';
import { connect } from 'react-redux';
import { Row, Col } from 'antd/lib/grid';
import { QuestionCircleOutlined } from '@ant-design/icons';
import Table from 'antd/lib/table';
import Modal from 'antd/lib/modal';
import Spin from 'antd/lib/spin';
import Text from 'antd/lib/typography/Text';

import CVATTooltip from 'components/common/cvat-tooltip';
import { CombinedState, DimensionType } from 'reducers';
import { showStatistics,showFrames } from 'actions/annotation-actions';

interface StateToProps {
    visible: boolean;
    collecting: boolean;
    data: any;
    visibleFrame: boolean;
    collectFrame: boolean;
    dataFrame: any;
    jobStatus: string;
    savingJobStatus: boolean;
    bugTracker: string | null;
    startFrame: number;
    stopFrame: number;
    dimension: DimensionType;
    assignee: any | null;
    states: any;
}

interface DispatchToProps {
    closeStatistics(): void;
}

function mapStateToProps(state: CombinedState): StateToProps {
    const {
        annotation: {
            statistics: { visible, collecting, data },
            frames: { visibleFrame, collectFrame, dataFrame },
            annotations: { states },
            job: {
                saving: savingJobStatus,
                instance: { bugTracker, startFrame, stopFrame, assignee, dimension, status: jobStatus },
            },
        },
    } = state;

    return {
        visible,
        collecting,
        data,
        jobStatus,
        visibleFrame,
        collectFrame,
        dataFrame,
        savingJobStatus,
        bugTracker,
        startFrame,
        stopFrame,
        dimension,
        states,
        assignee: assignee?.username || 'Nobody',
    };
}

function mapDispatchToProps(dispatch: any): DispatchToProps {
    return {
        closeStatistics(): void {
            dispatch(showStatistics(false));
            dispatch(showFrames(false));
        },
    };
}

function StatisticsModalComponent(props: StateToProps & DispatchToProps): JSX.Element {
    const { collecting, data, visible,collectFrame, dataFrame, visibleFrame, states, assignee, startFrame, stopFrame, bugTracker, closeStatistics, dimension } =
        props;

    const is2D = dimension === DimensionType.DIM_2D;
    const baseProps = {
        cancelButtonProps: { style: { display: 'none' } },
        okButtonProps: { style: { width: 100 } },
        onOk: closeStatistics,
        width: 1024,
        visible,
        closable: false,
        className: 'cvat-statistics-popover',
    };

    const baseProps1 = {
        cancelButtonProps: { style: { display: 'none' } },
        okButtonProps: { style: { width: 100 } },
        onOk: closeStatistics,
        width: 1024,
        visible: visibleFrame,
        closable: false,
        className: 'cvat-statistics-popover',
    };
    if (collecting || !data) {
        return (
            <Modal {...baseProps}>
                <Spin style={{ margin: '0 50%' }} />
            </Modal>
        );
    }

    const rows = Object.keys(data.label).map((key: string) => ({
        key,
        label: key,
        rectangle: `${data.label[key].rectangle.shape} / ${data.label[key].rectangle.track}`,
        polygon: `${data.label[key].polygon.shape} / ${data.label[key].polygon.track}`,
        polyline: `${data.label[key].polyline.shape} / ${data.label[key].polyline.track}`,
        points: `${data.label[key].points.shape} / ${data.label[key].points.track}`,
        ellipse: `${data.label[key].ellipse.shape} / ${data.label[key].ellipse.track}`,
        cuboid: `${data.label[key].cuboid.shape} / ${data.label[key].cuboid.track}`,
        skeleton: `${data.label[key].skeleton.shape} / ${data.label[key].skeleton.track}`,
        mask: `${data.label[key].mask.shape}`,
        tag: data.label[key].tag,
        manually: data.label[key].manually,
        interpolated: data.label[key].interpolated,
        total: data.label[key].total,
    }));

    rows.push({
        key: '___total',
        label: 'Total',
        rectangle: `${data.total.rectangle.shape} / ${data.total.rectangle.track}`,
        polygon: `${data.total.polygon.shape} / ${data.total.polygon.track}`,
        polyline: `${data.total.polyline.shape} / ${data.total.polyline.track}`,
        points: `${data.total.points.shape} / ${data.total.points.track}`,
        ellipse: `${data.total.ellipse.shape} / ${data.total.ellipse.track}`,
        cuboid: `${data.total.cuboid.shape} / ${data.total.cuboid.track}`,
        skeleton: `${data.total.skeleton.shape} / ${data.total.skeleton.track}`,
        mask: `${data.total.mask.shape}`,
        tag: data.total.tag,
        manually: data.total.manually,
        interpolated: data.total.interpolated,
        total: data.total.total,
    });

    const makeShapesTracksTitle = (title: string): JSX.Element => (
        <CVATTooltip title={is2D && !(title.toLowerCase() === 'mask') ? 'Shapes / Tracks' : 'Shapes'}>
            <Text strong style={{ marginRight: 5 }}>
                {title}
            </Text>
            <QuestionCircleOutlined className='cvat-info-circle-icon' />
        </CVATTooltip>
    );

    const columns = [
        {
            title: <Text strong> Label </Text>,
            dataIndex: 'label',
            render: (text: string) => {
                const sep = '{{cvat.skeleton.lbl.sep}}';
                if (text.split(sep).length > 1) {
                    const [label, part] = text.split(sep);
                    return (
                        <>
                            <Text strong>{label}</Text>
                            {' \u2B95 '}
                            <Text strong>{part}</Text>
                        </>
                    );
                }

                return <Text strong>{text}</Text>;
            },
            fixed: 'left',
            key: 'label',
            width: 120,
        },
        {
            title: makeShapesTracksTitle('Rectangle'),
            dataIndex: 'rectangle',
            key: 'rectangle',
            width: 100,
        },
        {
            title: makeShapesTracksTitle('Polygon'),
            dataIndex: 'polygon',
            key: 'polygon',
            width: 100,
        },
        {
            title: makeShapesTracksTitle('Polyline'),
            dataIndex: 'polyline',
            key: 'polyline',
            width: 100,
        },
        {
            title: makeShapesTracksTitle('Points'),
            dataIndex: 'points',
            key: 'points',
            width: 100,
        },
        {
            title: makeShapesTracksTitle('Ellipse'),
            dataIndex: 'ellipse',
            key: 'ellipse',
            width: 100,
        },
        {
            title: makeShapesTracksTitle('Cuboid'),
            dataIndex: 'cuboid',
            key: 'cuboid',
            width: 100,
        },
        {
            title: makeShapesTracksTitle('Skeleton'),
            dataIndex: 'skeleton',
            key: 'skeleton',
            width: 100,
        },
        {
            title: makeShapesTracksTitle('Mask'),
            dataIndex: 'mask',
            key: 'mask',
            width: 100,
        },
        {
            title: <Text strong> Tag </Text>,
            dataIndex: 'tag',
            key: 'tag',
            width: 100,
        },
        {
            title: <Text strong> Manually </Text>,
            dataIndex: 'manually',
            key: 'manually',
            fixed: 'right',
            width: 100,
        },
        {
            title: <Text strong> Interpolated </Text>,
            dataIndex: 'interpolated',
            key: 'interpolated',
            fixed: 'right',
            width: 100,
        },
        {
            title: <Text strong> Total </Text>,
            dataIndex: 'total',
            key: 'total',
            fixed: 'right',
            width: 100,
        },
    ];

    const columns3D = [
        {
            title: <Text strong> Label </Text>,
            dataIndex: 'label',
            key: 'label',
        },
        {
            title: makeShapesTracksTitle('Cuboids'),
            dataIndex: 'cuboid',
            key: 'cuboid',
        },
        {
            title: <Text strong> Total </Text>,
            dataIndex: 'total',
            key: 'total',
        },
    ];


    const framesData: any = {}                                                  //added by Ganesh
    states.forEach((dt: any, index: number) => {
        framesData[dt.label.name] = { ...framesData[dt.label.name],
            [dt.objectType]: framesData[dt.label.name]?.[dt.objectType] ? framesData[dt.label.name][dt.objectType]+1 : 1 ,
        'total' : data.label[dt.label.name].total  }
    })
    const frameRows = Object.keys(framesData).map((key: string) => ({
        key,
        label: key,
        shape: framesData[key]?.shape | 0,
        track: framesData[key]?.track | 0,
        total : (framesData[key]?.shape | 0) + (framesData[key]?.track | 0) ,
    }));

if(frameRows.length >= 0){
    let totalShape=0
    let totalTrack=0
    let totalAll=0

    for(let i=0;i<frameRows.length;i++){
        totalShape += frameRows[i]?.shape
        totalTrack += frameRows[i]?.track
        totalAll += frameRows[i]?.total
    }
        frameRows.push({
            key: '___total',
            label: 'Total',
            shape: (totalShape  ),
            track: ( totalTrack ),
            total: ( totalAll ),
        });
    }

    const frameColumn2D = [
        {
            title: <Text strong> Label </Text>,
            dataIndex: 'label',
            render: (text: string) => {
                const sep = '{{cvat.skeleton.lbl.sep}}';
                if (text.split(sep).length > 1) {
                    const [label, part] = text.split(sep);
                    return (
                        <>
                            <Text strong>{label}</Text>
                            {' \u2B95 '}
                            <Text strong>{part}</Text>
                        </>
                    );
                }

                return <Text strong>{text}</Text>;
            },
            fixed: 'left',
            key: 'label',
            width: 120,
        },
        {
            title: <Text strong> Shapes </Text>,
            dataIndex: 'shape',
            key: 'shape',
            width: 100,
        },
        {
            title:<Text strong> Track </Text>,
            dataIndex: 'track',
            key: 'track',
            width: 100,
        },
        {
            title: <Text strong> Total </Text>,
            dataIndex: 'total',
            key: 'total',
            fixed: 'right',
            width: 100,
        },
    ];

    const framesColumns3D: any = [
        {
            title: <Text strong> Label </Text>,
            dataIndex: 'label',
            key: 'label',
        },
        {
            title: <Text strong> Shapes </Text>,
            dataIndex: 'shape',
            key: 'shape',
        },
        {
            title: <Text strong> Track </Text>,
            dataIndex: 'track',
            key: 'track',
        },
        {
            title: <Text strong> Total </Text>,
            dataIndex: 'total',
            key: 'total',
        },
    ];

    return (
        <div>
        {
            visible && <Modal {...baseProps}>
            <div className='cvat-job-info-modal-window'>
                <Row justify='start' className='job-info-modal-header'>
                    <Col>
                        <Text className='cvat-text'>Overview</Text>
                    </Col>
                </Row>
                <Row justify='start' className='job-info-modal-subheader'>
                    <Col span={4}>
                        <Text strong className='cvat-text'>
                            Assignee
                        </Text>
                        <Text className='cvat-text'>{assignee}</Text>
                    </Col>
                    <Col span={4}>
                        <Text strong className='cvat-text'>
                            Start frame
                        </Text>
                        <Text className='cvat-text'>{startFrame}</Text>
                    </Col>
                    <Col span={4}>
                        <Text strong className='cvat-text'>
                            Stop frame
                        </Text>
                        <Text className='cvat-text'>{stopFrame}</Text>
                    </Col>
                    <Col span={4}>
                        <Text strong className='cvat-text'>
                            Frames
                        </Text>
                        <Text className='cvat-text'>{stopFrame - startFrame + 1}</Text>
                    </Col>
                </Row>
                {!!bugTracker && (
                    <Row justify='start' className='cvat-job-info-bug-tracker'>
                        <Col>
                            <Text strong className='cvat-text'>
                                Bug tracker
                            </Text>
                            <a href={bugTracker}>{bugTracker}</a>
                        </Col>
                    </Row>
                )}
                <Row justify='space-around' className='cvat-job-info-statistics'>
                    <Col span={24}>
                        <Text className='cvat-text'>Annotations statistics</Text>
                        <Table
                            // scroll={{ x: 'max-content', y: 400 }}
                            // bordered
                            pagination={false}
                            columns={is2D ? columns : columns3D}
                            dataSource={rows}
                        />
                    </Col>
                </Row>
            </div>
        </Modal>
         }

         {visibleFrame && <Modal {...baseProps1}>
             <div className='cvat-job-info-modal-window'>
                 <Row justify='start' className='job-info-modal-header'>
                     <Col>
                         <Text className='cvat-text'>Frames</Text>
                     </Col>
                 </Row>
                 <Row justify='start' className='job-info-modal-subheader'>


                     <Col span={6}>
                         <Text strong className='cvat-text'>
                             Frame_Number : {states[0]?.frame || 0 }

                         </Text>
                         <Text className='cvat-text'>Total : {states.length || 0 }</Text>
                     </Col>


                 </Row>
                 {!!bugTracker && (
                     <Row justify='start' className='cvat-job-info-bug-tracker'>
                         <Col>
                             <Text strong className='cvat-text'>
                                 Bug tracker
                             </Text>
                             <a href={bugTracker}>{bugTracker}</a>
                         </Col>
                     </Row>
                 )}
                 <Row justify='space-around' className='cvat-job-info-statistics'>
                     <Col span={24}>
                         <Text className='cvat-text'>Annotations statistics</Text>
                         <Table
                             // scroll={{ x: 'max-content', y: 400 }}
                             // bordered
                             pagination={false}
                             columns={is2D ? frameColumn2D : framesColumns3D}
                             dataSource={frameRows}
                         />
                     </Col>
                 </Row>
             </div>
         </Modal>
         }
         </div>
    );
}

export default connect(mapStateToProps, mapDispatchToProps)(StatisticsModalComponent);
