// Copyright (C) 2020-2022 Intel Corporation
//
// SPDX-License-Identifier: MIT

import React, { Dispatch } from 'react';
import { useEffect, useState } from 'react';
import { AnyAction } from 'redux';
import { connect, useSelector } from 'react-redux';
import Text from 'antd/lib/typography/Text';
import Radio, { RadioChangeEvent } from 'antd/lib/radio';
import Slider from 'antd/lib/slider';
import Checkbox, { CheckboxChangeEvent } from 'antd/lib/checkbox';
import Collapse from 'antd/lib/collapse';
import Button from 'antd/lib/button';
import { Select, Space } from 'antd';
const { Option } = Select;
import Icon,{ RadarChartOutlined, WarningOutlined } from '@ant-design/icons';
import ColorPicker from 'components/annotation-page/standard-workspace/objects-side-bar/color-picker';
import { ColorizeIcon,InfoIcon } from 'icons';
import { ColorBy, CombinedState, DimensionType, PlayerSettingsState } from 'reducers';
import { collapseAppearance as collapseAppearanceAction, collectStatesAsync as collectStatesAction, showStatesValidation } from 'actions/annotation-actions';
import {
    changeShapesColorBy as changeShapesColorByAction,
    changeShapesOpacity as changeShapesOpacityAction,
    changeSelectedShapesOpacity as changeSelectedShapesOpacityAction,
    changeShapesOutlinedBorders as changeShapesOutlinedBordersAction,
    changeShowBitmap as changeShowBitmapAction,
    changeShowProjections as changeShowProjectionsAction,
    changeShapesColorByAttr as changeShapesColorByAttrAction,
} from 'actions/settings-actions';
import './styles.scss';
interface StateToProps {
    appearanceCollapsed: boolean;
    colorBy: ColorBy;
    opacity: number;
    selectedOpacity: number;
    outlined: boolean;
    outlineColor: string;
    showBitmap: boolean;
    showProjections: boolean;
    jobInstance: any;
}

interface DispatchToProps {
    collapseAppearance(): void;
    changeShapesColorBy(event: RadioChangeEvent): void;
    changeShapesOpacity(value: number): void;
    changeSelectedShapesOpacity(value: number): void;
    changeShapesOutlinedBorders(outlined: boolean, color: string): void;
    changeShowBitmap(event: CheckboxChangeEvent): void;
    changeShowProjections(event: CheckboxChangeEvent): void;
    changeShapesColorByAtt(Attribute: string, values: string): void;
    showValidations(sessionInstance: any): void;
}

function mapStateToProps(state: CombinedState): StateToProps {
    const {
        annotation: {
            appearanceCollapsed,
            job: { instance: jobInstance },
        },
        settings: {
            shapes: { colorBy, opacity, selectedOpacity, outlined, outlineColor, showBitmap, showProjections },
        },
    } = state;
    return {
        appearanceCollapsed,
        colorBy,
        opacity,
        selectedOpacity,
        outlined,
        outlineColor,
        showBitmap,
        showProjections,
        jobInstance,
    };
}

function mapDispatchToProps(dispatch: Dispatch<AnyAction>): DispatchToProps {
    return {
        collapseAppearance(): void {
            dispatch(collapseAppearanceAction());
        },
        changeShapesColorBy(event: RadioChangeEvent): void {
            dispatch(changeShapesColorByAction(event.target.value));
        },
        changeShapesOpacity(value: number): void {
            dispatch(changeShapesOpacityAction(value));
        },
        changeSelectedShapesOpacity(value: number): void {
            dispatch(changeSelectedShapesOpacityAction(value));
        },
        changeShapesOutlinedBorders(outlined: boolean, color: string): void {
            dispatch(changeShapesOutlinedBordersAction(outlined, color));
        },
        changeShowBitmap(event: CheckboxChangeEvent): void {
            dispatch(changeShowBitmapAction(event.target.checked));
        },
        changeShowProjections(event: CheckboxChangeEvent): void {
            dispatch(changeShowProjectionsAction(event.target.checked));
        },
        changeShapesColorByAtt(Attribute: string, values: string): void {
            dispatch(changeShapesColorByAttrAction(Attribute, values));
        },
        showValidations(jobInstance): void {
            dispatch(collectStatesAction(jobInstance));
            dispatch(showStatesValidation(true));
        },

    };
}

type Props = StateToProps & DispatchToProps;

function AppearanceBlock(props: Props): JSX.Element {
    const
    allowtosubmit
= useSelector((state: CombinedState): PlayerSettingsState => state.settings?.player.allowsubmit);
    const [selectAttribute, setAttribute] = useState();
    const [attributeList, setListAttribute] = useState([
        [
            {
                id: 1,
                AttributeName: 'is_group',
            },
            {
                id: 2,
                AttributeName: 'attached_to',
            },
            {
                id: 3,
                AttributeName: 'is_active_agent',
            },
            {
                id: 4,
                AttributeName: 'oncoming',
            },
            {
                id: 5,
                AttributeName: 'crossing',
            },
            {
                id: 6,
                AttributeName: 'reference_sensor',
            },
            {
                id: 7,
                AttributeName: 'protruding',
            },
            {
                id: 8,
                AttributeName: 'vehical_cut',
            },
            {
                id: 9,
                AttributeName: 'cipv',
            },
            {
                id: 10,
                AttributeName: 'lane_mapping',
            },
            {
                id: 11,
                AttributeName: 'emergency_vehicle',
            },
            {
                id: 12,
                AttributeName: 'emergency_light',
            },
            {
                id: 13,
                AttributeName: 'emergency_lights_color',
            },
            {
                id: 14,
                AttributeName: 'caution_orange_light',
            },
            {
                id: 15,
                AttributeName: 'left_blinker',
            },
            {
                id: 16,
                AttributeName: 'right_blinker',
            },
            {
                id: 17,
                AttributeName: 'reverse_light',
            },
            {
                id: 18,
                AttributeName: 'brake_lights',
            },
            {
                id: 19,
                AttributeName: 'motion',
            },
            {
                id: 20,
                AttributeName: 'occlusion_level',
            },
            {
                id: 21,
                AttributeName: 'top_left',
            },
            {
                id: 22,
                AttributeName: 'top_center',
            },
            {
                id: 23,
                AttributeName: 'top_right',
            },
            {
                id: 24,
                AttributeName: 'middle_left',
            },
            {
                id: 25,
                AttributeName: 'middle_center',
            },
            {
                id: 26,
                AttributeName: 'middle_right',
            },
            {
                id: 27,
                AttributeName: 'bottom_left',
            },
            {
                id: 28,
                AttributeName: 'bottom_center',
            },
            {
                id: 29,
                AttributeName: 'bottom_right',
            },
            {
                id: 30,
                AttributeName: 'UUID',
            },
            {
                id: 31,
                AttributeName: 'head_orientation',
            },
            {
                id: 32,
                AttributeName: 'pedestrian_pose',
            },
            {
                id: 33,
                AttributeName: 'controls_traffic',
            },
            {
                id: 34,
                AttributeName: 'is_child',
            },
            {
                id: 35,
                AttributeName: 'signaling_a_turn',
            },
            {
                id: 36,
                AttributeName: 'special_traffic_participant',
            },
            {
                id: 37,
                AttributeName: 'animal_size',
            },
        ],
    ]);

    useEffect(() => {}, [attributeList]);
    const {
        appearanceCollapsed,
        colorBy,
        opacity,
        selectedOpacity,
        outlined,
        outlineColor,
        showBitmap,
        showProjections,
        collapseAppearance,
        changeShapesColorBy,
        changeShapesOpacity,
        changeSelectedShapesOpacity,
        changeShapesOutlinedBorders,
        changeShowBitmap,
        changeShowProjections,
        jobInstance,
        changeShapesColorByAtt,
        showValidations,
    } = props;
    const is2D = jobInstance.dimension === DimensionType.DIM_2D;
    const onChangeAttribute = (values) => {
        changeShapesColorByAtt(selectAttribute, values);
    };
    const changeShapesColorByAttribute = (event) => {
        setAttribute(event.target.value);
    };
    const showValidation = (e): void => {
        e.preventDefault();
        const { jobInstance } = props;
        showValidations(jobInstance);
    };

    const allowtoSubmit = (e): void => {
        e.preventDefault();
        const MyDataToPass: any = [];
    const Flags = ['flag1', 'flag2', 'flag3', 'flag4', 'flag5'];
    for (let i = 0; i <= 5; i++) {
        const item = {};
        item[Flags[i]] = true;
        MyDataToPass.push(item);
    }
    const fiveValues = MyDataToPass.slice(0, 5);
    window.parent.postMessage({ message: 'getAppData', value: fiveValues }, '*');
    };
    return (
        <Collapse
            onChange={collapseAppearance}
            activeKey={appearanceCollapsed ? [] : ['appearance']}
            className='cvat-objects-appearance-collapse'
        >
            <Collapse.Panel
                header={
                    <Text strong className='cvat-objects-appearance-collapse-header'>
                        Appearance
                    </Text>
                }
                key='appearance'
            >
                <div className='cvat-objects-appearance-content'>
                    <Text className='cvat-text-color' type='secondary'>
                        Color by
                    </Text>
                    <Radio.Group
                        className='cvat-appearance-color-by-radio-group'
                        value={colorBy}
                        onChange={changeShapesColorBy}
                    >
                        <Radio.Button value={ColorBy.LABEL}>{ColorBy.LABEL}</Radio.Button>
                        <Radio.Button value={ColorBy.INSTANCE}>{ColorBy.INSTANCE}</Radio.Button>
                        <Radio.Button value={ColorBy.GROUP}>{ColorBy.GROUP}</Radio.Button>
                    </Radio.Group>
                    <div style={{ display: 'flex' }}>
                        <Radio.Group
                            className='cvat-appearance-color-by-radio'
                            value={colorBy}
                            onChange={changeShapesColorByAttribute}
                        >
                            <Radio.Button value={ColorBy.ATTRIBUTE}>{ColorBy.ATTRIBUTE}</Radio.Button>
                        </Radio.Group>
                        {selectAttribute && (
                            <Select
                                showSearch
                                style={{ width: 200 }}
                                placeholder='Select a Attribute'
                                onChange={onChangeAttribute}
                            >
                                {attributeList.map((item) => (
                                    <>
                                        {item.map((attribute) => (
                                            <Option key={attribute.id} value={attribute.AttributeName}>
                                                {attribute.AttributeName}
                                            </Option>
                                        ))}
                                    </>
                                ))}
                            </Select>
                        )}
                    </div>
                    <Text className='cvat-text-color' type='secondary'>
                        Opacity
                    </Text>
                    <Slider
                        className='cvat-appearance-opacity-slider'
                        onChange={changeShapesOpacity}
                        value={opacity}
                        min={0}
                        max={100}
                    />
                    <Text className='cvat-text-color' type='secondary'>
                        Selected opacity
                    </Text>
                    <Slider
                        className='cvat-appearance-selected-opacity-slider'
                        onChange={changeSelectedShapesOpacity}
                        value={selectedOpacity}
                        min={0}
                        max={100}
                    />
                    <Checkbox
                        className='cvat-appearance-outlinded-borders-checkbox'
                        onChange={(event: CheckboxChangeEvent) => {
                            changeShapesOutlinedBorders(event.target.checked, outlineColor);
                        }}
                        checked={outlined}
                    >
                        Outlined borders
                        <ColorPicker
                            onChange={(color) => changeShapesOutlinedBorders(outlined, color)}
                            value={outlineColor}
                            placement='top'
                            resetVisible={false}
                        >
                            <Button className='cvat-appearance-outlined-borders-button' type='link' shape='circle'>
                                <ColorizeIcon />
                            </Button>
                        </ColorPicker>
                    </Checkbox>
                    {is2D && (
                        <Checkbox
                            className='cvat-appearance-bitmap-checkbox'
                            onChange={changeShowBitmap}
                            checked={showBitmap}
                        >
                            Show bitmap
                        </Checkbox>
                    )}
                    {is2D && (
                        <Checkbox
                            className='cvat-appearance-cuboid-projections-checkbox'
                            onChange={changeShowProjections}
                            checked={showProjections}
                        >
                            Show projections
                        </Checkbox>
                    )}

            <Space wrap>
            <Button
                type='link'
                className='cvat-annotation-header-info-button cvat-annotation-header-button'
                onClick={(e) =>
                    showValidation(e)}
            ><WarningOutlined />
       Show Error
            </Button>
            <Button
                type='link'
                className={
                    allowtosubmit ?
                        'cvat-annotation-header-info-button cvat-annotation-header-button1 disable' :
                        'cvat-annotation-header-info-button cvat-annotation-header-button1'
                }
                onClick={(e) =>
                    allowtoSubmit(e)}
            ><RadarChartOutlined />
             Allow to Submit
            </Button>
  </Space>
                </div>
            </Collapse.Panel>
        </Collapse>
    );
}

export default connect(mapStateToProps, mapDispatchToProps)(React.memo(AppearanceBlock));
