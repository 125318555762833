// Copyright (C) 2020-2022 Intel Corporation
//
// SPDX-License-Identifier: MIT

import React, { useState, useEffect, useRef } from 'react';
import { Col } from 'antd/lib/grid';
import Select from 'antd/lib/select';
import Radio, { RadioChangeEvent } from 'antd/lib/radio';
import Checkbox, { CheckboxChangeEvent } from 'antd/lib/checkbox';
import Input from 'antd/lib/input';
import InputNumber from 'antd/lib/input-number';
import Text from 'antd/lib/typography/Text';
import { Button } from 'antd';
import config from 'config';
import { clamp } from 'utils/math';
import * as validator from 'validator';

interface Props {
    readonly: boolean;
    attrInputType: string;
    attrValues: string[];
    attrValue: string;
    attrName: string;
    attrID: number;
    changeAttribute(attrID: number, value: string): void;
    UUIDAttach: string[];
}

export function attrIsTheSame(prevProps: Props, nextProps: Props): boolean {
    return (
        nextProps.readonly === prevProps.readonly &&
        nextProps.attrID === prevProps.attrID &&
        nextProps.attrValue === prevProps.attrValue &&
        nextProps.attrName === prevProps.attrName &&
        nextProps.attrInputType === prevProps.attrInputType &&
        nextProps.UUIDAttach.map((value: string, id: number): boolean => prevProps.UUIDAttach[id] === value).every(
            (value: boolean): boolean => value,
        ) &&
        nextProps.attrValues
            .map((value: string, id: number): boolean => prevProps.attrValues[id] === value)
            .every((value: boolean): boolean => value)
    );
}

function ItemAttributeComponent(props: Props): JSX.Element {
    let { attrInputType, attrValues, attrValue, attrName, attrID, readonly, changeAttribute, UUIDAttach } = props;

    const attrNameStyle: React.CSSProperties = { wordBreak: 'break-word', lineHeight: '1em' };

    const d0 = (Math.random() * 0xffffffff) | 0;
    const d1 = (Math.random() * 0xffffffff) | 0;
    const d2 = (Math.random() * 0xffffffff) | 0;
    const d3 = (Math.random() * 0xffffffff) | 0;
    const _lut = [
        '00',
        '01',
        '02',
        '03',
        '04',
        '05',
        '06',
        '07',
        '08',
        '09',
        '0a',
        '0b',
        '0c',
        '0d',
        '0e',
        '0f',
        '10',
        '11',
        '12',
        '13',
        '14',
        '15',
        '16',
        '17',
        '18',
        '19',
        '1a',
        '1b',
        '1c',
        '1d',
        '1e',
        '1f',
        '20',
        '21',
        '22',
        '23',
        '24',
        '25',
        '26',
        '27',
        '28',
        '29',
        '2a',
        '2b',
        '2c',
        '2d',
        '2e',
        '2f',
        '30',
        '31',
        '32',
        '33',
        '34',
        '35',
        '36',
        '37',
        '38',
        '39',
        '3a',
        '3b',
        '3c',
        '3d',
        '3e',
        '3f',
        '40',
        '41',
        '42',
        '43',
        '44',
        '45',
        '46',
        '47',
        '48',
        '49',
        '4a',
        '4b',
        '4c',
        '4d',
        '4e',
        '4f',
        '50',
        '51',
        '52',
        '53',
        '54',
        '55',
        '56',
        '57',
        '58',
        '59',
        '5a',
        '5b',
        '5c',
        '5d',
        '5e',
        '5f',
        '60',
        '61',
        '62',
        '63',
        '64',
        '65',
        '66',
        '67',
        '68',
        '69',
        '6a',
        '6b',
        '6c',
        '6d',
        '6e',
        '6f',
        '70',
        '71',
        '72',
        '73',
        '74',
        '75',
        '76',
        '77',
        '78',
        '79',
        '7a',
        '7b',
        '7c',
        '7d',
        '7e',
        '7f',
        '80',
        '81',
        '82',
        '83',
        '84',
        '85',
        '86',
        '87',
        '88',
        '89',
        '8a',
        '8b',
        '8c',
        '8d',
        '8e',
        '8f',
        '90',
        '91',
        '92',
        '93',
        '94',
        '95',
        '96',
        '97',
        '98',
        '99',
        '9a',
        '9b',
        '9c',
        '9d',
        '9e',
        '9f',
        'a0',
        'a1',
        'a2',
        'a3',
        'a4',
        'a5',
        'a6',
        'a7',
        'a8',
        'a9',
        'aa',
        'ab',
        'ac',
        'ad',
        'ae',
        'af',
        'b0',
        'b1',
        'b2',
        'b3',
        'b4',
        'b5',
        'b6',
        'b7',
        'b8',
        'b9',
        'ba',
        'bb',
        'bc',
        'bd',
        'be',
        'bf',
        'c0',
        'c1',
        'c2',
        'c3',
        'c4',
        'c5',
        'c6',
        'c7',
        'c8',
        'c9',
        'ca',
        'cb',
        'cc',
        'cd',
        'ce',
        'cf',
        'd0',
        'd1',
        'd2',
        'd3',
        'd4',
        'd5',
        'd6',
        'd7',
        'd8',
        'd9',
        'da',
        'db',
        'dc',
        'dd',
        'de',
        'df',
        'e0',
        'e1',
        'e2',
        'e3',
        'e4',
        'e5',
        'e6',
        'e7',
        'e8',
        'e9',
        'ea',
        'eb',
        'ec',
        'ed',
        'ee',
        'ef',
        'f0',
        'f1',
        'f2',
        'f3',
        'f4',
        'f5',
        'f6',
        'f7',
        'f8',
        'f9',
        'fa',
        'fb',
        'fc',
        'fd',
        'fe',
        'ff',
    ];
    const uuid =
        _lut[d0 & 0xff] +
        _lut[(d0 >> 8) & 0xff] +
        _lut[(d0 >> 16) & 0xff] +
        _lut[(d0 >> 24) & 0xff] +
        '-' +
        _lut[d1 & 0xff] +
        _lut[(d1 >> 8) & 0xff] +
        '-' +
        _lut[((d1 >> 16) & 0x0f) | 0x40] +
        _lut[(d1 >> 24) & 0xff] +
        '-' +
        _lut[(d2 & 0x3f) | 0x80] +
        _lut[(d2 >> 8) & 0xff] +
        '-' +
        _lut[(d2 >> 16) & 0xff] +
        _lut[(d2 >> 24) & 0xff] +
        _lut[d3 & 0xff] +
        _lut[(d3 >> 8) & 0xff] +
        _lut[(d3 >> 16) & 0xff] +
        _lut[(d3 >> 24) & 0xff];
    const [uuidValue, setUuidValue] = useState(uuid);
    const [options, setOptions] = useState(UUIDAttach);
    const [isReadOnly,setIsReadOnly] = useState(true);
    const handleSearch = (value: any) => {
        const filteredOptions = UUIDAttach.filter((option: any) => option.includes(value));
        setOptions(filteredOptions);
    };
    useEffect(() => {
        setOptions(UUIDAttach);
    }, [UUIDAttach, attrID]);
    if (attrInputType === 'checkbox') {
        return (
            <Col span={24}>
                <Checkbox
                    className='cvat-object-item-checkbox-attribute'
                    checked={attrValue === 'true'}
                    disabled={readonly}
                    onChange={(event: CheckboxChangeEvent): void => {
                        const value = event.target.checked ? 'true' : 'false';
                        changeAttribute(attrID, value);
                    }}
                >
                    <Text style={attrNameStyle} className='cvat-text'>
                        {attrName}
                    </Text>
                </Checkbox>
            </Col>
        );
    }

    if (attrInputType === 'radio') {
        return (
            <Col span={24}>
                <fieldset className='cvat-object-item-radio-attribute'>
                    <legend>
                        <Text style={attrNameStyle} className='cvat-text'>
                            {attrName}
                        </Text>
                    </legend>
                    <Radio.Group
                        disabled={readonly}
                        size='small'
                        value={attrValue}
                        onChange={(event: RadioChangeEvent): void => {
                            changeAttribute(attrID, event.target.value);
                        }}
                    >
                        {attrValues.map(
                            (value: string): JSX.Element => (
                                <Radio key={value} value={value}>
                                    {value === config.UNDEFINED_ATTRIBUTE_VALUE ? config.NO_BREAK_SPACE : value}
                                </Radio>
                            ),
                        )}
                    </Radio.Group>
                </fieldset>
            </Col>
        );
    }

    // if (attrInputType === 'select') {
    //     return (
    //         <>
    //             <Col span={8} style={attrNameStyle}>
    //                 <Text className='cvat-text'>{attrName}</Text>
    //             </Col>
    //             <Col span={16}>
    //                 <Select
    //                     disabled={readonly}
    //                     size='small'
    //                     onChange={(value: string): void => {
    //                         changeAttribute(attrID, value);
    //                     }}
    //                     value={attrValue}
    //                     className='cvat-object-item-select-attribute'
    //                 >
    //                     {attrValues.map(
    //                         (value: string): JSX.Element => (
    //                             <Select.Option key={value} value={value}>
    //                                 {value === config.UNDEFINED_ATTRIBUTE_VALUE ? config.NO_BREAK_SPACE : value}
    //                             </Select.Option>
    //                         ),
    //                     )}
    //                 </Select>
    //             </Col>
    //         </>
    //     );
    // }
    if (attrInputType === 'select') {
        return (
            <>
                {' '}
                {attrName.toLowerCase() !== 'attached_to' ? (
                    <>
                        <Col span={8} style={attrNameStyle}>
                            <Text className='cvat-text'>{attrName}</Text>
                        </Col>

                        <Col span={16}>
                            <Select
                                disabled={readonly}
                                size='small'
                                onChange={(value: string): void => {
                                    changeAttribute(attrID, value);
                                }}
                                value={attrValue}
                                className='cvat-object-item-select-attribute'
                                dropdownClassName='cvat-object-item-select-attribute-dropdown'
                            >
                                {attrValues.map(
                                    (value: string): JSX.Element => (
                                        <Select.Option key={value} value={value}>
                                            {value === config.UNDEFINED_ATTRIBUTE_VALUE ? config.NO_BREAK_SPACE : value}
                                        </Select.Option>
                                    ),
                                )}
                            </Select>
                        </Col>
                    </>
                ) : (
                    <>
                        <Col span={8} style={attrNameStyle}>
                            <Text className='cvat-text'>{attrName}</Text>
                        </Col>

                        <Col span={16}>
                            <Select
                                showSearch
                                placeholder='select a UUID'
                                onSearch={(value) => handleSearch(value)}
                                disabled={readonly}
                                size='small'
                                onChange={(value: string): void => {
                                    changeAttribute(attrID, value);
                                }}
                                value={attrValue}
                                className='cvat-object-item-select-attribute'
                                dropdownClassName='cvat-object-item-select-attribute-dropdown'
                            >
                                <Select.Option value='NA'>{'NA'}</Select.Option>

                                {options.map(
                                    (value: string): JSX.Element => (
                                        <Select.Option key={value} value={value}>
                                            {value === config.UNDEFINED_ATTRIBUTE_VALUE ? config.NO_BREAK_SPACE : value}
                                        </Select.Option>
                                    ),
                                )}
                            </Select>
                        </Col>
                    </>
                )}
            </>
        );
    }
    if (attrInputType === 'number') {
        const [min, max, step] = attrValues.map((value: string): number => +value);
        return (
            <>
                <Col span={8} style={attrNameStyle}>
                    <Text className='cvat-text'>{attrName}</Text>
                </Col>
                <Col span={16}>
                    <InputNumber
                        disabled={readonly}
                        size='small'
                        onChange={(value: number | undefined | string): void => {
                            if (typeof value !== 'undefined') {
                                changeAttribute(attrID, `${clamp(+value, min, max)}`);
                            }
                        }}
                        value={+attrValue}
                        className='cvat-object-item-number-attribute'
                        min={min}
                        max={max}
                        step={step}
                    />
                </Col>
            </>
        );
    }

    const generateUUID = () => {
        // const d0 = Math.random() * 0xffffffff | 0;
        // const d1 = Math.random() * 0xffffffff | 0;
        // const d2 = Math.random() * 0xffffffff | 0;
        // const d3 = Math.random() * 0xffffffff | 0;
        // const _lut = ['00', '01', '02', '03', '04', '05', '06', '07', '08', '09', '0a', '0b', '0c', '0d', '0e', '0f', '10', '11', '12', '13', '14', '15', '16', '17', '18', '19', '1a', '1b', '1c', '1d', '1e', '1f', '20', '21', '22', '23', '24', '25', '26', '27', '28', '29', '2a', '2b', '2c', '2d', '2e', '2f', '30', '31', '32', '33', '34', '35', '36', '37', '38', '39', '3a', '3b', '3c', '3d', '3e', '3f', '40', '41', '42', '43', '44', '45', '46', '47', '48', '49', '4a', '4b', '4c', '4d', '4e', '4f', '50', '51', '52', '53', '54', '55', '56', '57', '58', '59', '5a', '5b', '5c', '5d', '5e', '5f', '60', '61', '62', '63', '64', '65', '66', '67', '68', '69', '6a', '6b', '6c', '6d', '6e', '6f', '70', '71', '72', '73', '74', '75', '76', '77', '78', '79', '7a', '7b', '7c', '7d', '7e', '7f', '80', '81', '82', '83', '84', '85', '86', '87', '88', '89', '8a', '8b', '8c', '8d', '8e', '8f', '90', '91', '92', '93', '94', '95', '96', '97', '98', '99', '9a', '9b', '9c', '9d', '9e', '9f', 'a0', 'a1', 'a2', 'a3', 'a4', 'a5', 'a6', 'a7', 'a8', 'a9', 'aa', 'ab', 'ac', 'ad', 'ae', 'af', 'b0', 'b1', 'b2', 'b3', 'b4', 'b5', 'b6', 'b7', 'b8', 'b9', 'ba', 'bb', 'bc', 'bd', 'be', 'bf', 'c0', 'c1', 'c2', 'c3', 'c4', 'c5', 'c6', 'c7', 'c8', 'c9', 'ca', 'cb', 'cc', 'cd', 'ce', 'cf', 'd0', 'd1', 'd2', 'd3', 'd4', 'd5', 'd6', 'd7', 'd8', 'd9', 'da', 'db', 'dc', 'dd', 'de', 'df', 'e0', 'e1', 'e2', 'e3', 'e4', 'e5', 'e6', 'e7', 'e8', 'e9', 'ea', 'eb', 'ec', 'ed', 'ee', 'ef', 'f0', 'f1', 'f2', 'f3', 'f4', 'f5', 'f6', 'f7', 'f8', 'f9', 'fa', 'fb', 'fc', 'fd', 'fe', 'ff'];
        // const uuid = _lut[d0 & 0xff] + _lut[d0 >> 8 & 0xff] + _lut[d0 >> 16 & 0xff] + _lut[d0 >> 24 & 0xff] + '-' + _lut[d1 & 0xff] + _lut[d1 >> 8 & 0xff] + '-' + _lut[d1 >> 16 & 0x0f | 0x40] + _lut[d1 >> 24 & 0xff] + '-' + _lut[d2 & 0x3f | 0x80] + _lut[d2 >> 8 & 0xff] + '-' + _lut[d2 >> 16 & 0xff] + _lut[d2 >> 24 & 0xff] + _lut[d3 & 0xff] + _lut[d3 >> 8 & 0xff] + _lut[d3 >> 16 & 0xff] + _lut[d3 >> 24 & 0xff];
        setUuidValue(uuid);
        if (uuid) {
            // console.log(uuid);
            changeAttribute(attrID, uuid);
            // console.log("attid AFTER",attrID,uuid);
        }
    };

    const ref = useRef<Input>(null);
    const [selection, setSelection] = useState<{
        start: number | null;
        end: number | null;
        direction: 'forward' | 'backward' | 'none' | null;
    }>({
        start: null,
        end: null,
        direction: null,
    });

    const handlePaste = (event) => {
        const pastedText = event.clipboardData?.getData('text');
        const key = event.keyCode;
        if (key == 8 || key == 46) {
            event.preventDefault();
            return false;
        }
        if (pastedText) {
            if (validator.isUUID(pastedText, 4)) {
                setIsReadOnly(false);
                attrValue = pastedText;
                return true;
            } else {
                alert('Invalid UUID. Please paste a valid UUID');
                event.preventDefault();
            }
        }
    };

    useEffect(() => {
        if (ref.current && ref.current.input) {
            ref.current.input.selectionStart = selection.start;
            ref.current.input.selectionEnd = selection.end;
            ref.current.input.selectionDirection = selection.direction;
        }
    }, [attrValue]);

    useEffect(() => {
        if (attrName.toLowerCase() === 'uuid' && (attrValue === 'NA' || !attrValue)) {
            changeAttribute(attrID, uuidValue);
        }
    }, []);

    return (
        <>
            <Col span={8} style={attrNameStyle}>
                <Text className='cvat-text'>{attrName}</Text>
            </Col>
            <Col span={16}>
                <Input
                    ref={ref}
                    size='small'
                    readOnly={isReadOnly}
                    id='uuidInput'
                    maxLength={36}
                    onKeyDown={(event) => handlePaste(event)}
                    onPaste={(event)=>handlePaste(event)}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>): void => {
                        if (ref.current && ref.current.input) {
                            setSelection({
                                start: ref.current.input.selectionStart,
                                end: ref.current.input.selectionEnd,
                                direction: ref.current.input.selectionDirection,
                            });
                        }
                        changeAttribute(attrID, event.target.value);
                    }}
                    value={attrName.toLowerCase() === 'uuid' && attrValue === '' ? uuidValue : attrValue}
                    className='cvat-object-item-text-attribute'
                />
                {attrName.toLowerCase() === 'uuid' && (
                    <Button onClick={generateUUID} className='cvat-generate-uuid'>
                        Generate UUID
                    </Button>
                )}
            </Col>
        </>
    );
}

export default React.memo(ItemAttributeComponent, attrIsTheSame);
// export default ItemAttributeComponent;
