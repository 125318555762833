// Copyright (C) 2020-2022 Intel Corporation
//
// SPDX-License-Identifier: MIT

import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Row, Col } from 'antd/lib/grid';
import Table from 'antd/lib/table';
import Modal from 'antd/lib/modal';
import Spin from 'antd/lib/spin';
import Text from 'antd/lib/typography/Text';

import { CombinedState, DimensionType, PlayerSettingsState } from 'reducers';
import { showStatesValidation } from 'actions/annotation-actions';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { DimensionSubmit } from 'actions/settings-actions';
interface StateToProps {
    visibleValue: boolean;
    collectingdata: boolean;
    Statesdata: any;
}

interface DispatchToProps {
    closeStatistics(): void;
}

function mapStateToProps(state: CombinedState): StateToProps {
    const {
        annotation: {
            statesList: { visibleValue, collectingdata, Statesdata },
        },
    } = state;

    return {
        visibleValue,
        collectingdata,
        Statesdata,
    };
}

function mapDispatchToProps(dispatch: any): DispatchToProps {
    return {
        closeStatistics(): void {
            dispatch(showStatesValidation(false));
        },
    };
}

function StatesModalComponent(props: StateToProps & DispatchToProps): JSX.Element {
    const { collectingdata, Statesdata, visibleValue, closeStatistics } = props;
    const baseProps = {
        cancelButtonProps: { style: { display: 'none' } },
        okButtonProps: { style: { width: 100 } },
        onOk: closeStatistics,
        width: 1024,
        visibleValue,
        closable: false,
        className: 'cvat-statistics-popover',
    };
    const dispatch = useDispatch();
    // Safe to add dispatch to the dependencies array
    const [occlusion_Error, setocclusion_Error] = useState([]);
    useEffect(() => {
        if (visibleValue === true && Statesdata) {
            checkValidations(true);
            for (const object of Object.values(Statesdata)) {
                if (object?._removed === true) {
                    continue;
                }
            }
        }
    }, [props]);

    const default_dimensions: any[] = [
        {
            CAR: { min: { length: 2.51, width: 1.41, height: 1.21 }, max: { length: 6.99, width: 2.49, height: 1.79 } },
            VAN: { min: { length: 4.01, width: 1.61, height: 1.81 }, max: { length: 7.99, width: 2.79, height: 3.49 } },
            TRUCK: {
                min: { length: 6.01, width: 2.01, height: 2.01 },
                max: { length: 19.99, width: 2.99, height: 4.49 },
            },
            TRAILER: {
                min: { length: 2.01, width: 1.01, height: 0.81 },
                max: { length: 19.99, width: 2.99, height: 4.49 },
            },
            BUS: {
                min: { length: 6.01, width: 2.01, height: 2.21 },
                max: { length: 15.99, width: 2.79, height: 4.49 },
            },
            ONRAILS: {
                min: { length: 6.01, width: 1.81, height: 2.01 },
                max: { length: 49.99, width: 3.49, height: 4.49 },
            },
            SCOOTER: {
                min: { length: 1.51, width: 0.31, height: 0.81 },
                max: { length: 2.49, width: 1.19, height: 1.49 },
            },
            KICK_SCOOTER: {
                min: { length: 0.51, width: 0.31, height: 0.51 },
                max: { length: 1.49, width: 0.99, height: 1.49 },
            },
            RICKSHAW: {
                min: { length: 1.01, width: 1.01, height: 0.51 },
                max: { length: 1.99, width: 1.99, height: 1.99 },
            },
            PICKUP: {
                min: { length: 5.01, width: 1.61, height: 0.51 },
                max: { length: 7.99, width: 2.49, height: 1.99 },
            },
            WHEELCHAIR: {
                min: { length: 0.51, width: 0.51, height: 0.51 },
                max: { length: 1.49, width: 0.99, height: 1.49 },
            },
            STROLLER: {
                min: { length: 0.51, width: 0.51, height: 0.81 },
                max: { length: 1.49, width: 0.99, height: 1.49 },
            },
            MOTORBIKE: {
                min: { length: 1.31, width: 0.31, height: 0.81 },
                max: { length: 2.49, width: 1.19, height: 1.49 },
            },
            CONSTRUCTION_SITE_VEHICLE: {
                min: { length: 3.01, width: 1.51, height: 1.01 },
                max: { length: 19.99, width: 2.99, height: 3.99 },
            },
            BICYCLE: {
                min: { length: 1.31, width: 0.31, height: 0.61 },
                max: { length: 2.19, width: 0.79, height: 1.49 },
            },
            PERSON: {
                min: { length: 0.21, width: 0.21, height: 1.41 },
                max: { length: 1.19, width: 1.19, height: 2.19 },
            },
            ANIMAL: {
                min: { length: 0.01, width: 0.01, height: 0.01 },
                max: { length: 4.99, width: 4.99, height: 4.99 },
            },
            HAZARD: {
                min: { length: 0.01, width: 0.01, height: 0.01 },
                max: { length: 4.99, width: 4.99, height: 4.99 },
            },
            OTHER_VEHICLE: {
                min: { length: 0.51, width: 0.51, height: 0.51 },
                max: { length: 19.99, width: 2.49, height: 4.49 },
            },
            OTHER_TRAFFIC_PARTICIPANT: {
                min: { length: 0.01, width: 0.01, height: 0.01 },
                max: { length: 4.99, width: 4.99, height: 4.99 },
            },
            VEHICLE_DOOR: { min: { length: 0.01, width: 0.01, height: 0.01 }, max: { length: 5, width: 5, height: 5 } },
            UNKNOWN: {
                min: { length: 0.01, width: 0.01, height: 0.01 },
                max: { length: 4.99, width: 4.99, height: 4.99 },
            },
        },
    ];
    const occlusion_conditions = [
        'top_left',
        'top_center',
        'top_right',
        'middle_left',
        'middle_center',
        'middle_right',
        'bottom_left',
        'bottom_right',
        'bottom_center',
    ];
    let occlusion_Errors: any[] = [];
    let unique: any[] = [];
    let uniqueness: any[] = [];
    let UUID_list: any[] = [];
    let collectAllPoints: any[] = []; //add by akshay
    let initialDuplicatedObject = new Set();
    let collectedPointMap = new Map<string, object>();

    const samePositionValidations = (
        collectedCuboidPoints: any,
        object: any,
        keysTrack: number,
        checkOutsideProperty: any,
    ) => {
        const valueOfCollectedPoints = `${keysTrack || object?.frame}-${checkOutsideProperty}-${
            collectedCuboidPoints[0]
        },${collectedCuboidPoints[1]},${collectedCuboidPoints[2]}`; //frame wise duplication

        if (collectAllPoints.includes(valueOfCollectedPoints)) {
            initialDuplicatedObject.add(valueOfCollectedPoints);
            occlusion_Errors.push({
                frameno: keysTrack || object?.frame,
                id: object?.clientID,
                positions: 'Duplicate boxes with same position',
                level: 'Duplicate boxes',
            });
        } else {
            if (checkOutsideProperty === false || checkOutsideProperty === null) {
                collectAllPoints.push(valueOfCollectedPoints);
                collectedPointMap.set(valueOfCollectedPoints, {
                    frameno: keysTrack || object?.frame,
                    id: object?.clientID,
                });
            }
        }
    };

    const checkValidations = (event: any) => {
        const occlusion_boolean: { [x: string]: any; id: any }[] = [];
        const occlusion_attributes: { [x: string]: any; id: any }[] = [];
        const finalattributesValue: any[] = [];
        let atributesValue: any;
        let lane_mapping: { id: any; lane_mapping: any; frameNumber: any }[] = [];
        let is_active_agent: any[] = [];
        let attached_to_value: any[] = [];
        if (event) {
            // Main objects filter start
            console.time('myTime1');
            for (const object of Object.values(Statesdata)) {
                if (object?._removed === true) {
                    continue;
                }
                let filterValue: any[] = [];
                let labelAttribut_value: any;
                let labelAttributes: any;
                if (object?.shapes) {
                    validationsTrack(object); // Track validation method calling here
                } else {
                    labelAttribut_value = object?.label?.attributes;
                    labelAttributes = object?.attributes;
                    let collectedCuboidPointsforShape = object?.points;
                    labelAttribut_value.forEach((val: any) => {
                        for (const key in labelAttributes) {
                            if (labelAttributes.hasOwnProperty(key)) {
                                if (Number(key) === val.id) {
                                    atributesValue = {
                                        [val.name]: labelAttributes[key],
                                        clientId: object?.clientID,
                                        frameNumber: object?.frame,
                                    };
                                }
                            }
                        }
                        filterValue.push(atributesValue);
                    });
                    finalattributesValue.push(filterValue);
                    //dimesion
                    let labelPoints = object?.points?.slice(6, 9);
                    let clientid = object?.clientID;
                    let frameNumber = object?.frame;
                    let labelName = object?.label?.name;
                    let min_length = default_dimensions[0][labelName]['min']['length'];
                    let min_width = default_dimensions[0][labelName]['min']['width'];
                    let min_height = default_dimensions[0][labelName]['min']['height'];
                    let max_length = default_dimensions[0][labelName]['max']['length'];
                    let max_width = default_dimensions[0][labelName]['max']['width'];
                    let max_height = default_dimensions[0][labelName]['max']['height'];
                    if (labelPoints[0] < min_width) {
                        occlusion_Errors.push({
                            frameno: frameNumber,
                            id: clientid,
                            positions: 'Width is not within the defined thresholds',
                            level: 'minwidth',
                            flag: true,
                        });
                    }
                    if (labelPoints[0] > max_width) {
                        occlusion_Errors.push({
                            frameno: frameNumber,
                            id: clientid,
                            positions: 'Width is not within the defined thresholds',
                            level: 'maxwidth',
                            flag: true,
                        });
                    }
                    if (labelPoints[1] < min_length) {
                        occlusion_Errors.push({
                            frameno: frameNumber,
                            id: clientid,
                            positions: 'length is not within the defined thresholds',
                            level: 'minlength',
                            flag: true,
                        });
                    }
                    if (labelPoints[1] > max_length) {
                        occlusion_Errors.push({
                            frameno: frameNumber,
                            id: clientid,
                            positions: 'length is not within the defined thresholds',
                            level: 'maxlength',
                            flag: true,
                        });
                    }
                    if (labelPoints[2] < min_height) {
                        occlusion_Errors.push({
                            frameno: frameNumber,
                            id: clientid,
                            positions: 'height is not within the defined thresholds',
                            level: 'minheight',
                            flag: true,
                        });
                    }
                    if (labelPoints[2] > max_height) {
                        occlusion_Errors.push({
                            frameno: frameNumber,
                            id: clientid,
                            positions: 'height is not within the defined thresholds',
                            level: 'maxheight',
                            flag: true,
                        });
                    }
                    samePositionValidations(collectedCuboidPointsforShape, object, object?.frame, null); //add by akshay
                }
            }

            // Main objects filter end by Raju
            //second loop for main objects start
            finalattributesValue?.forEach((item: any) => {
                item.forEach((sub: any) => {
                    let keys = Object?.keys(sub);
                    occlusion_conditions.forEach((occul) => {
                        if (keys[0] === occul) {
                            let oculate = keys[0];
                            if (sub[keys[0]] === 'true') {
                                occlusion_attributes.push({ id: sub[keys[1]], [oculate]: sub[keys[0]] });
                            }
                        } else if (keys[0] === 'occlusion_level') {
                            occlusion_boolean.push({ id: sub[keys[1]], [keys[0]]: sub[keys[0]], frame: sub[keys[2]] });
                        }
                    });
                    if (keys[0] === 'is_active_agent') {
                        is_active_agent.push({ id: sub[keys[1]], [keys[0]]: sub[keys[0]], frameNumber: sub[keys[2]] });
                    } else if (keys[0] === 'lane_mapping') {
                        lane_mapping.push({ id: sub[keys[1]], [keys[0]]: sub[keys[0]], frameNumber: sub[keys[2]] });
                    } else if (keys[0] === 'attached_to') {
                        attached_to_value.push({
                            id: sub[keys[1]],
                            [keys[0]]: sub[keys[0]],
                            frameNumber: sub[keys[2]],
                        });
                    } else if (keys[0] === 'UUID') {
                        UUID_list.push({ id: sub[keys[1]], [keys[0]]: sub[keys[0]], frameNumber: sub[keys[2]] });
                    }
                });
            });
            // code for is_active_agent,lane_mapping start;
            lane_mapping?.map((item) => {
                is_active_agent?.map((itm) => {
                    if (item.id === itm.id) {
                        if (itm.is_active_agent === 'true' && item.lane_mapping === 'BEHIND_BOUNDARY') {
                            occlusion_Errors.push({
                                id: item.id,
                                frameno: item.frameNumber,
                                positions: 'IS_Active_Agent',
                                level: 'True/Behind_boundry',
                            });
                        }
                    }
                });
            });
            attached_to_value.map((item) => {
                is_active_agent.map((itm) => {
                    if (item.id === itm.id) {
                        if (itm.is_active_agent === 'true' && item.attached_to !== 'NA') {
                            occlusion_Errors.push({
                                id: item.id,
                                frameno: item.frameNumber,
                                positions: 'Attached_to',
                                level: 'Is_Active_Agent true',
                            });
                        }
                    }
                });
            });
            // code for is_active_agent,lane_mapping end;

            //second loop for main objects end above
            const occlusionLevelunique = occlusion_boolean.filter((obj, index) => {
                return index === occlusion_boolean.findIndex((o) => obj.id === o.id);
            }); // removed duplicate
            let keylists: any[] = [];
            occlusion_attributes.forEach((i) => {
                keylists.push(i.id);
            }); // seggregated id in the array list
            let counts = Array.from(
                keylists.reduce((r, c) => r.set(c, (r.get(c) || 0) + 1), new Map()),
                ([key, count]) => ({ key, count }),
            ); // count duplicate values in the array objects
            unique = Array.from(new Set(occlusionLevelunique.map((obj) => obj.frame))).map((id) =>
                occlusionLevelunique.find((obj) => obj.frame === id),
            );
            occlusionLevelunique.forEach((item) => {
                let count = { key: '', count: 0 };
                if (keylists.includes(item.id)) {
                } else {
                    count['key'] = item.id;
                    count['count'] = 0;
                    counts.push(count);
                }
            });
            // finding occlusion level based on Attributes position values below
            occlusionLevelunique.forEach((occul) => {
                counts.forEach((cnt) => {
                    if (cnt.key === occul.id) {
                        if (occul.occlusion_level === '100' && cnt.count !== 9) {
                            occlusion_Errors.push({
                                id: occul.id,
                                frameno: occul?.frame,
                                positions: 'Occlusion_Error',
                                level: 'Position/Level',
                            });
                        } else if (occul.occlusion_level === '0' && cnt.count !== 0) {
                            occlusion_Errors.push({
                                id: occul.id,
                                frameno: occul?.frame,
                                positions: 'Occlusion_Error',
                                level: 'Position/Level',
                            });
                        } else if (occul.occlusion_level > '0' && cnt.count === 0) {
                            occlusion_Errors.push({
                                id: occul.id,
                                frameno: occul?.frame,
                                positions: 'Occlusion_Error',
                                level: 'Position/Level',
                            });
                        }
                    }
                });
            });
            if (UUID_list.length > 0) {
                const isDuplicate = function () {
                    const doubleValue = [];
                    for (let i = 0; i < UUID_list.length; i++) {
                        for (let x = i + 1; x < UUID_list.length; x++) {
                            if (UUID_list[i].UUID === UUID_list[x].UUID && UUID_list[i].id !== UUID_list[x].id) {
                                doubleValue.push(UUID_list[x].UUID);
                            }
                        }
                    }
                    return [...new Set(doubleValue)];
                };

                let doubleUUId = isDuplicate();
                const UUIDUniqueList = UUID_list.filter((obj, index) => {
                    return index === UUID_list.findIndex((o) => obj.id === o.id);
                });
                UUIDUniqueList.filter((obj) => {
                    if (doubleUUId.includes(obj.UUID)) {
                        occlusion_Errors.push({
                            frameno: obj?.frameNumber,
                            id: obj.id,
                            positions: obj.UUID,
                            level: 'UUID Dublicate',
                        });
                    }
                });
            }
            console.timeEnd('myTime1');
        } else {
            occlusion_Errors = [];
        }
        //Loop start for initial object start(same position validation)
        //add by akshay
        for (const points of initialDuplicatedObject) {
            if (collectedPointMap.has(points)) {
                const frameDetails = collectedPointMap.get(points);
                occlusion_Errors.push({
                    frameno: frameDetails?.frameno,
                    id: frameDetails?.id,
                    positions: 'Duplicate boxes with same position',
                    level: 'Duplicate boxes',
                });
            }
        }
        initialDuplicatedObject.clear();
        setocclusion_Error(occlusion_Errors);
        //enable and disable buttons
        const MyDataToPass: any = [];
        const Flags = ['flag1', 'flag2', 'flag3', 'flag4', 'flag5'];
        for (let i = 0; i <= 5; i++) {
            const item = {};
            item[Flags[i]] = false;
            MyDataToPass.push(item);
        }
        if (uniqueness?.length > 0 || unique?.length > 0) {
            for (let i = 0; i < Flags.length; i++) {
                if (occlusion_Errors.length === 0) {
                    MyDataToPass[i].flag1 = true;
                    MyDataToPass[i].flag2 = true;
                    MyDataToPass[i].flag3 = true;
                    MyDataToPass[i].flag4 = true;
                    MyDataToPass[i].flag5 = true;
                    const fiveValues = MyDataToPass.slice(0, 5);
                    window.parent.postMessage({ message: 'getAppData', value: fiveValues }, '*');
                } else {
                    MyDataToPass[i].flag1 = false;
                    MyDataToPass[i].flag2 = false;
                    MyDataToPass[i].flag3 = false;
                    MyDataToPass[i].flag4 = false;
                    MyDataToPass[i].flag5 = false;
                    window.parent.postMessage({ message: 'getAppData', value: MyDataToPass }, '*');
                }
            }
        }
    };
    //validations for Track
    const validationsTrack = (object: any) => {
        let keysValues = Object.keys(object?.shapes);
        keysValues.forEach((keysTrack) => {
            validatetrackError(object, keysTrack);
        });
        return;
    };
    const validatetrackError = (object: any, keysTrack: any) => {
        let labelTrackattr: any;
        let TrackAttributes: any;
        let trackChecking: any[] = [];
        const finalTrackValues: any[] = [];
        const occlusion_boolean: { [x: string]: any; id: any }[] = [];
        const occlusionTrackattributes: { [x: string]: any; id: any }[] = [];
        let atributesValue: any;
        let lane_mapping: { id: any; lane_mapping: any; frameNumber: any }[] = [];
        let is_active_agent: any[] = [];
        let attached_to_value: any[] = [];
        labelTrackattr = object?.label?.attributes;
        let defaultAttributes = object?.attributes;
        let checkAttributes = object?.shapes[keysTrack]?.attributes;
        let collectedCuboidPointsForTrack = object?.shapes[keysTrack]?.points; //add by akshay
        let checkOutsideProperty = object?.shapes[keysTrack]?.outside;
        samePositionValidations(collectedCuboidPointsForTrack, object, keysTrack, checkOutsideProperty); //add by akshay
        if (Object.keys(checkAttributes).length === 0 && checkAttributes.constructor === Object) {
            TrackAttributes = defaultAttributes;
        } else {
            for (let key in defaultAttributes) {
                for (let items in checkAttributes) {
                    if (items === key) {
                        defaultAttributes[key] = checkAttributes[items];
                    }
                }
                TrackAttributes = defaultAttributes;
            }
        }
        labelTrackattr.forEach((val: any) => {
            for (const key in TrackAttributes) {
                if (TrackAttributes.hasOwnProperty(key)) {
                    if (Number(key) === val.id) {
                        atributesValue = {
                            [val.name]: TrackAttributes[key],
                            clientId: object?.clientID,
                            frameNumber: keysTrack,
                        };
                    }
                }
            }
            trackChecking.push(atributesValue);
        });
        finalTrackValues.push(trackChecking);

        finalTrackValues?.forEach((item: any) => {
            item.forEach((sub: any) => {
                let keys = Object?.keys(sub);
                occlusion_conditions.forEach((occul) => {
                    if (keys[0] === occul) {
                        let oculate = keys[0];
                        if (sub[keys[0]] === 'true') {
                            occlusionTrackattributes.push({ id: sub[keys[1]], [oculate]: sub[keys[0]] });
                        }
                    } else if (keys[0] === 'occlusion_level') {
                        occlusion_boolean.push({ id: sub[keys[1]], [keys[0]]: sub[keys[0]], frame: sub[keys[2]] });
                    }
                });
                if (keys[0] === 'is_active_agent') {
                    is_active_agent.push({ id: sub[keys[1]], [keys[0]]: sub[keys[0]], frameNumber: sub[keys[2]] });
                } else if (keys[0] === 'lane_mapping') {
                    lane_mapping.push({ id: sub[keys[1]], [keys[0]]: sub[keys[0]], frameNumber: sub[keys[2]] });
                } else if (keys[0] === 'attached_to') {
                    attached_to_value.push({
                        id: sub[keys[1]],
                        [keys[0]]: sub[keys[0]],
                        frameNumber: sub[keys[2]],
                    });
                } else if (keys[0] === 'UUID') {
                    UUID_list.push({ id: sub[keys[1]], [keys[0]]: sub[keys[0]], frameNumber: sub[keys[2]] });
                }
            });
        });
        // track active agent/Behind_boundry validations below
        lane_mapping.map((item) => {
            is_active_agent.map((itm) => {
                if (item.id === itm.id) {
                    if (itm.is_active_agent === 'true' && item.lane_mapping === 'BEHIND_BOUNDARY') {
                        occlusion_Errors.push({
                            id: item.id,
                            frameno: keysTrack,
                            positions: 'IS_Active_Agent',
                            level: 'True/Behind_boundry',
                        });
                    }
                }
            });
        });
        // track active agent/Behind_boundry validations end above
        // track active agent/Attached_to validations code below
        attached_to_value.map((item) => {
            is_active_agent.map((itm) => {
                if (item.id === itm.id) {
                    if (itm.is_active_agent === 'true' && item.attached_to !== 'NA') {
                        occlusion_Errors.push({
                            id: item.id,
                            frameno: keysTrack,
                            positions: 'Attached_to',
                            level: 'Is_Active_Agent true',
                        });
                    }
                }
            });
        });

        const occlusionLevelunique = occlusion_boolean.filter((obj, index) => {
            return index === occlusion_boolean.findIndex((o) => obj.id === o.id);
        }); // removed duplicate
        let keylists: any[] = [];
        occlusionTrackattributes.forEach((i) => {
            keylists.push(i.id);
        }); // seggregated id in the array list
        let counts = Array.from(
            keylists.reduce((r, c) => r.set(c, (r.get(c) || 0) + 1), new Map()),
            ([key, count]) => ({ key, count }),
        ); // count duplicate values in the array objects
        uniqueness = Array.from(new Set(occlusionLevelunique.map((obj) => obj.frame))).map((id) =>
            occlusionLevelunique.find((obj) => obj.frame === id),
        );
        occlusionLevelunique.forEach((item) => {
            let count = { key: '', count: 0 };
            if (keylists.includes(item.id)) {
            } else {
                count['key'] = item.id;
                count['count'] = 0;
                counts.push(count);
            }
        });
        // finding occlusion level based on Attributes position values below
        occlusionLevelunique.forEach((occul) => {
            counts.forEach((cnt) => {
                if (cnt.key === occul.id) {
                    if (occul.occlusion_level === '100' && cnt.count !== 9) {
                        occlusion_Errors.push({
                            id: occul.id,
                            frameno: keysTrack,
                            positions: 'Occlusion_Error',
                            level: 'Position/Level',
                        });
                    } else if (occul.occlusion_level === '0' && cnt.count !== 0) {
                        occlusion_Errors.push({
                            id: occul.id,
                            frameno: keysTrack,
                            positions: 'Occlusion_Error',
                            level: 'Position/Level',
                        });
                    } else if (occul.occlusion_level > '0' && cnt.count === 0) {
                        occlusion_Errors.push({
                            id: occul.id,
                            frameno: keysTrack,
                            positions: 'Occlusion_Error',
                            level: 'Position/Level',
                        });
                    }
                }
            });
        });
        let labelPoints: any;
        labelPoints = object?.shapes[keysTrack]?.points?.slice(6, 9);
        let labelName = object?.label?.name;
        let min_length = default_dimensions[0][labelName]['min']['length'];
        let min_width = default_dimensions[0][labelName]['min']['width'];
        let min_height = default_dimensions[0][labelName]['min']['height'];

        let max_length = default_dimensions[0][labelName]['max']['length'];
        let max_width = default_dimensions[0][labelName]['max']['width'];
        let max_height = default_dimensions[0][labelName]['max']['height'];
        if (labelPoints[0] < min_width) {
            occlusion_Errors.push({
                frameno: keysTrack,
                id: object?.clientID,
                positions: 'Width is not within the defined thresholds',
                level: 'minwidth',
                flag: true,
            });
        }
        if (labelPoints[0] > max_width) {
            occlusion_Errors.push({
                frameno: keysTrack,
                id: object?.clientID,
                positions: 'Width is not within the defined thresholds',
                level: 'maxwidth',
                flag: true,
            });
        }
        if (labelPoints[1] < min_length) {
            occlusion_Errors.push({
                frameno: keysTrack,
                id: object?.clientID,
                positions: 'length is not within the defined thresholds',
                level: 'minlength',
                flag: true,
            });
        }
        if (labelPoints[1] > max_length) {
            occlusion_Errors.push({
                frameno: keysTrack,
                id: object?.clientID,
                positions: 'length is not within the defined thresholds',
                level: 'maxlength',
                flag: true,
            });
        }
        if (labelPoints[2] < min_height) {
            occlusion_Errors.push({
                frameno: keysTrack,
                id: object?.clientID,
                positions: 'height is not within the defined thresholds',
                level: 'minheight',
                flag: true,
            });
        }
        if (labelPoints[2] > max_height) {
            occlusion_Errors.push({
                frameno: keysTrack,
                id: object?.clientID,
                positions: 'height is not within the defined thresholds',
                level: 'maxheight',
                flag: true,
            });
        }
    };
    //validations for Track end.
    const columns = [
        {
            title: 'Frame_NO',
            dataIndex: 'frameno',
            key: 'frameno',
        },
        {
            title: 'Object_ID',
            dataIndex: 'ClientId',
            key: 'ClientId',
        },
        {
            title: 'Error_Category',
            dataIndex: 'Positions',
            key: 'Positions',
        },
        {
            title: 'Error_Type',
            dataIndex: 'Level',
            key: 'Level',
        },
    ];
    let dimension_flag: never[] = [];
    dimension_flag = occlusion_Error.filter((obj) => obj?.flag === true);
    useEffect(() => {
        if (dimension_flag?.length === occlusion_Error?.length) { //condition to enable Allow to Submit button
            dispatch(DimensionSubmit(false));
        } else {
            dispatch(DimensionSubmit(true));
        }
    }, [dimension_flag]);
    let occlusion_errors = occlusion_Error.filter((obj) => obj?.flag !== true);
    occlusion_errors.push(dimension_flag);
    let dataSource = occlusion_errors.flat().map((item, i) => ({
        id: i,
        frameno: item?.frameno,
        ClientId: item?.id,
        Positions: item?.positions,
        Level: item?.level,
    }));
    return (
        <>
            {occlusion_Error.length > 0 && (
                <Modal visible={visibleValue} {...baseProps}>
                    <div className='ltts-job-info-modal-window'>
                        <Row justify='start' className='job-info-modal-header'>
                            <Col>
                                <Text className='cvat-text'>Error List of objects</Text>
                            </Col>
                        </Row>
                        <Row justify='start' className='job-info-modal-subheader-appearance'>
                            <Col span={24} className='ltts-job-info-modal-text'>
                                <div>
                                    <Table
                                        rowKey={(record) => record.id}
                                        dataSource={dataSource.length > 0 ? dataSource : dataSource}
                                        columns={columns}
                                    />
                                </div>
                            </Col>
                        </Row>
                    </div>
                </Modal>
            )}
        </>
    );
}

export default connect(mapStateToProps, mapDispatchToProps)(StatesModalComponent);
