const colorAttributes = [
    // color by attributes for is group
    {
        name: 'is_group',
        input_type: 'select',
        mutable: true,
        values: [
            { true: '#FF0000' },
            { false: '#FFFFFF' },
        ],
        default_value: 'false',
    },
    // doubt for attached to attribute(default value is NA)
    {
        name: 'attached_to',
        input_type: 'select',
        mutable: true,
        values: [
            { NA: '#FF0000' },
        ],
        default_value: 'NA',
    },
    // color by attributes for Active agent
    {
        name: 'is_active_agent',
        input_type: 'select',
        mutable: true,
        values: [
            { true: '#FF0000' },
            { false: '#FFFFFF' },
        ],
        default_value: 'false',
    },
    // color by attributes for Oncoming
    {
        name: 'oncoming',
        input_type: 'select',
        mutable: true,
        values: [
            { true: '#FF0000' },
            { false: '#FFFFFF' },
        ],
        default_value: 'false',
    },
    // color by attributes for Crossing
    {
        name: 'crossing',
        input_type: 'select',
        mutable: true,
        values: [
            { true: '#FF0000' },
            { false: '#FFFFFF' },
        ],
        default_value: 'false',
    },
    // color by attributes for Reference sensor
    {
        name: 'reference_sensor',
        input_type: 'select',
        mutable: true,
        values: [
            { CAMERA_FRONT_MAIN: '#FFFFFF' },
            { LIDAR: '#FFA500' },
            { RADAR: '#FFA500' },
        ],
        default_value: 'CAMERA_FRONT_MAIN',
    },
    // color by attributes for Protruding
    {
        name: 'protruding',
        input_type: 'select',
        mutable: true,
        values: [
            { NOT_APPLICABLE: '#808000' },
            { LEFT: '#A52A2A' },
            { RIGHT: '#008000' },
            { FRONT: '#7FFFD4' },
            { REAR: '#800080' },
        ],
        default_value: 'NOT_APPLICABLE',
    },
    // color by attributes for Cut
    {
        name: 'vehical_cut',
        input_type: 'select',
        mutable: true,
        values: [
            { NOT_APPLICABLE: '#FF00FF' },
            { CUT_IN: '#A52A2A' },
            { CLOSE_CUT_IN: '#008000' },
            { CUT_OUT: '#7FFFD4' },
            { NO_CUT: '#800080' },
            { UNKNOWN: '#0000FF' },
        ],
        default_value: 'NOT_APPLICABLE',
    },
    // color by attributes for CIPV
    {
        name: 'cipv',
        input_type: 'select',
        mutable: true,
        values: [
            { YES: '#FFFFFF' },
            { NO: '#FFA500' },
            { UNKNOWN: '#7FFFD4' },
            { NOT_APPLICABLE: '#00008B' },
        ],
        default_value: 'NOT_APPLICABLE',
    },
    // color by attributes for Lane mapping
    {
        name: 'lane_mapping',
        input_type: 'select',
        mutable: true,
        values: [
            { EGO_LANE: '#FFFF00' },
            { NEXT_LEFT: '#00FF00' },
            { NEXT_RIGHT: '#FF00FF' },
            { FURTHER_LEFT: '#7FFFD4' },
            { FURTHER_RIGHT: '#800000' },
            { BEHIND_BOUNDARY: '#00008B' },
            { UNKNOWN: '#C0C0C0' },
            { NOT_APPLICABLE: '#FF0000' },
        ],
        default_value: 'NOT_APPLICABLE',
    },
    // color by attributes for Emergency vehicle
    {
        name: 'emergency_vehicle',
        input_type: 'select',
        mutable: true,
        values: [
            { NONE: '#FFFFFF' },
            { POLICE: '#FFA500' },
            { AMBULANCE: '#7FFFD4' },
            { FIREFIGHTER: '#00008B' },
        ],
        default_value: 'NONE',
    },
    {
        name: 'emergency_light',
        input_type: 'select',
        mutable: true,
        values: [
            { OFF: '#FF00FF' },
            { ON: '#A52A2A' },
            { UNKNOWN: '#7FFFD4' },
            { NOT_APPLICABLE: '#FFFFFF' },
        ],
        default_value: 'NOT_APPLICABLE',
    },
    {
        name: 'emergency_lights_color',
        input_type: 'select',
        mutable: true,
        values: [
            { RED: '#808080' },
            { ORANGE: '#A52A2A' },
            { BLUE: '#7FFFD4' },
            { OTHER: '#FFC0CB' },
            { UNKNOWN: '#ADD8E6' },
            { NOT_APPLICABLE: '#FFFFFF' },
        ],
        default_value: 'NOT_APPLICABLE',
    },
    // color by attributes for caution orange light
    {
        name: 'caution_orange_light',
        input_type: 'select',
        mutable: true,
        values: [
            { OFF: '#808080' },
            { ON: '#A52A2A' },
            { UNKNOWN: '#7FFFD4' },
            { NOT_APPLICABLE: '#FFFFFF' },
        ],
        default_value: 'NOT_APPLICABLE',
    },
    // color by attributes for blinkers
    {
        name: 'left_blinker',
        input_type: 'select',
        mutable: true,
        values: [
            { OFF: '#808080' },
            { ON: '#A52A2A' },
            { UNKNOWN: '#7FFFD4' },
            { NOT_APPLICABLE: '#FFFFFF' },
        ],
        default_value: 'NOT_APPLICABLE',
    },
    {
        name: 'right_blinker',
        input_type: 'select',
        mutable: true,
        values: [
            { OFF: '#808080' },
            { ON: '#A52A2A' },
            { UNKNOWN: '#7FFFD4' },
            { NOT_APPLICABLE: '#FFFFFF' },
        ],
        default_value: 'NOT_APPLICABLE',
    },
    {
        name: 'reverse_light',
        input_type: 'select',
        mutable: true,
        values: [
            { OFF: '#808080' },
            { ON: '#A52A2A' },
            { UNKNOWN: '#7FFFD4' },
            { NOT_APPLICABLE: '#FFFFFF' },
        ],
        default_value: 'NOT_APPLICABLE',
    },
    {
        name: 'brake_lights',
        input_type: 'select',
        mutable: true,
        values: [
            { OFF: '#808080' },
            { ON: '#A52A2A' },
            { UNKNOWN: '#7FFFD4' },
            { NOT_APPLICABLE: '#FFFFFF' },
        ],
        default_value: 'NOT_APPLICABLE',
    },
    // color by attributes for motion
    {
        name: 'motion',
        input_type: 'select',
        mutable: true,
        values: [
            { MOVING: '#808080' },
            { STANDING: '#A52A2A' },
            { PARKING: '#800080' },
            { UNKNOWN: '#7FFFD4' },
        ],
        default_value: 'UNKNOWN',
    },
    // color by attributes for occlusion level
    {
        name: 'occlusion_level',
        input_type: 'select',
        mutable: true,
        values: [
            { 0: '#FFFFFF' },
            { 25: '#808080' },
            { 50: '#A52A2A' },
            { 75: '#808000' },
            { 100: '#FF0000' },
        ],
        default_value: '0',
    },
    // color by attributes for occlusion position
    {
        name: 'top_left',
        input_type: 'select',
        mutable: true,
        values: [
            { true: '#FF0000' },
            { false: '#FFFFFF' },
        ],
        default_value: 'false',
    },
    {
        name: 'top_center',
        input_type: 'select',
        mutable: true,
        values: [
            { true: '#FF0000' },
            { false: '#FFFFFF' },
        ],
        default_value: 'false',
    },
    {
        name: 'top_right',
        input_type: 'select',
        mutable: true,
        values: [
            { true: '#FF0000' },
            { false: '#FFFFFF' },
        ],
        default_value: 'false',
    },
    {
        name: 'middle_left',
        input_type: 'select',
        mutable: true,
        values: [
            { true: '#FF0000' },
            { false: '#FFFFFF' },
        ],
        default_value: 'false',
    },
    {
        name: 'middle_center',
        input_type: 'select',
        mutable: true,
        values: [
            { true: '#FF0000' },
            { false: '#FFFFFF' },
        ],
        default_value: 'false',
    },
    {
        name: 'middle_right',
        input_type: 'select',
        mutable: true,
        values: [
            { true: '#FF0000' },
            { false: '#FFFFFF' },
        ],
        default_value: 'false',
    },
    {
        name: 'bottom_left',
        input_type: 'select',
        mutable: true,
        values: [
            { true: '#FF0000' },
            { false: '#FFFFFF' },
        ],
        default_value: 'false',
    },
    {
        name: 'bottom_center',
        input_type: 'select',
        mutable: true,
        values: [
            { true: '#FF0000' },
            { false: '#FFFFFF' },
        ],
        default_value: 'false',
    },
    {
        name: 'bottom_right',
        input_type: 'select',
        mutable: true,
        values: [
            { true: '#FF0000' },
            { false: '#FFFFFF' },
        ],
        default_value: 'false',
    },
    // doubt for UUID attribute(default value is NA)
    {
        name: 'UUID',
        input_type: 'select',
        mutable: true,
        values: [
            { NA: '#FF0000' },
        ],
        default_value: 'NA',
    },
    // color by attributes for person
    {
        name: 'head_orientation',
        input_type: 'select',
        mutable: true,
        values: [
            { UNKNOWN: '#FFFFFF' },
            { LEFT: '#C0C0C0' },
            { BACK_LEFT: '#A52A2A' },
            { BACK: '#808000' },
            { BACK_RIGHT: '#FFC0CB' },
            { RIGHT: '#00FF00' },
            { FRONT_RIGHT: '#800080' },
            { FRONT: '#00FFFF' },
            { FRONT_LEFT: '#151B8D' },
        ],
        default_value: 'UNKNOWN',
    },
    {
        name: 'pedestrian_pose',
        input_type: 'select',
        mutable: true,
        values: [
            { STANDING: '#FFFFFF' },
            { UNKNOWN: '#C0C0C0' },
            { SITTING: '#A52A2A' },
            { LYING: '#808000' },
            { KNEELING: '#FFC0CB' },
            { WALKING: '#00FF00' },
            { RUNNING: '#800080' },
            { HEAVILY_BENDING: '#00FFFF' },
            { CRAWLING: '#151B8D' },
            { RIDING: '#151B8D' },
            { GETTING_INTO_OUT_OF_VEHICLE: '#151B8D' },
        ],
        default_value: 'UNKNOWN',
    },
    {
        name: 'controls_traffic',
        input_type: 'select',
        mutable: true,
        values: [
            { true: '#FF0000' },
            { false: '#FFFFFF' },
        ],
        default_value: 'false',
    },
    {
        name: 'is_child',
        input_type: 'select',
        mutable: true,
        values: [
            { true: '#FF0000' },
            { false: '#FFFFFF' },
        ],
        default_value: 'false',
    },
    {
        name: 'signaling_a_turn',
        input_type: 'select',
        mutable: true,
        values: [
            { NONE: '#FFFFFF' },
            { SIGNALING_TURN_LEFT: '#C0C0C0' },
            { SIGNALING_TURN_RIGHT: '#A52A2A' },
        ],
        default_value: 'UNKNOWN',
    },
    {
        name: 'special_traffic_participant',
        input_type: 'select',
        mutable: true,
        values: [
            { NONE: '#FFFFFF' },
            { POLICEMAN: '#FFA500' },
            { FIRE_FIGHTER: '#A52A2A' },
            { CONSTRUCTION_WORKER: '#808000' },
            { PARAMEDIC: '#FFC0CB' },
        ],
        default_value: 'NONE',
    },
    // color by attributes for Animal
    {
        name: 'animal_size',
        input_type: 'select',
        mutable: true,
        values: [
            { BIG: '#FFFFFF' },
            { SMALL: '#808080' },
        ],
        default_value: 'SMALL',
    },
];

// const colorAttributes = [
//     {
//         name: 'cipv',
//         input_type: 'select',
//         mutable: true,
//         values: [{ YES: '#33ddff' }, { NO: '#fa3253' }, { UNKNOWN: '#ff007c' }, { NOT_APPLICABLE: '#34d1b7' }],
//         default_value: 'YES',
//     },
//     {
//         name: 'emergency_lights',
//         input_type: 'select',
//         mutable: true,
//         values: [{ OFF: '#ffcc33' }, { ON: '#83e070' }, { UNKNOWN: '#ff007c' }, { NOT_APPLICABLE: '#34d1b7' }],
//         default_value: 'OFF',
//     },
//     {
//         name: 'lane_mapping',
//         input_type: 'select',
//         mutable: true,
//         values: [
//             { EGO_LANE: '#ff6037' },
//             { NEXT_LEFT: '#ddff33' },
//             { NEXT_RIGHT: '#24b353' },
//             { FURTHER_LEFT: '#b83df5' },
//             { FURTHER_RIGHT: '#66ff66' },
//             { BEHIND_BOUNDARY: '#32b7fa' },
//             { UNKNOWN: '#ff007c' },
//             { NOT_APPLICABLE: '#34d1b7' },
//         ],
//         default_value: 'EGO_LANE',
//     },
// ];

export default colorAttributes;
// export const colors = [
//     '#33ddff',
//     '#fa3253',
//     '#34d1b7',
//     '#ff007c',
//     '#ff6037',
//     '#ddff33',
//     '#24b353',
//     '#b83df5',
//     '#66ff66',
//     '#32b7fa',
//     '#ffcc33',
//     '#83e070',
//     '#fafa37',
//     '#5986b3',
//     '#8c78f0',
//     '#ff6a4d',
//     '#f078f0',
//     '#2a7dd1',
//     '#b25050',
//     '#cc3366',
//     '#cc9933',
//     '#aaf0d1',
//     '#ff00cc',
//     '#3df53d',
//     '#fa32b7',
//     '#fa7dbb',
//     '#ff355e',
//     '#f59331',
//     '#3d3df5',
//     '#733380',
// ];
