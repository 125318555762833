// Copyright (C) 2020-2022 Intel Corporation
//
// SPDX-License-Identifier: MIT

import React, { useState, useEffect, useCallback, useRef } from 'react';

import { Row, Col } from 'antd/lib/grid';
import Icon, { LinkOutlined, DeleteOutlined } from '@ant-design/icons';
import Slider from 'antd/lib/slider';
import InputNumber from 'antd/lib/input-number';
import Input from 'antd/lib/input';
import Text from 'antd/lib/typography/Text';

// import { RestoreIcon } from 'icons';
import CVATTooltip from 'components/common/cvat-tooltip';
import { clamp } from 'utils/math';
import { Modal, Form, Button } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import debounce from 'lodash/debounce';
import {
    activateObject as activateObjectAction,
    collectStatesAsync as collectStatesAction,
} from 'actions/annotation-actions';
import GlobalHotKeys, { KeyMap } from 'utils/mousetrap-react';

interface Props {
    startFrame: number;
    stopFrame: number;
    playing: boolean;
    frameNumber: number;
    frameFilename: string;
    // frameDeleted: boolean;
    deleteFrameShortcut: string;
    focusFrameInputShortcut: string;
    inputFrameRef: React.RefObject<Input>;
    onSliderChange(value: number): void;
    onInputChange(value: number): void;
    onURLIconClick(): void;
    onDeleteFrame(): void;
    onRestoreFrame(): void;
    switchNavigationBlocked(blocked: boolean): void;
    onFrameIconSearch(value: string): void;
    jobInstance: any;
}

function PlayerNavigation(props: Props): JSX.Element {

    const dispatch = useDispatch();
    const {
        startFrame,
        stopFrame,
        playing,
        frameNumber,
        frameFilename,
        // frameDeleted,
        // deleteFrameShortcut,
        focusFrameInputShortcut,
        inputFrameRef,
        onSliderChange,
        onInputChange,
        onURLIconClick,
        onDeleteFrame,
        // onRestoreFrame,
        switchNavigationBlocked,
        jobInstance,
        onFrameIconSearch,
    } = props;

    const inputRefOne = useRef(null);
    const [frameInputValue, setFrameInputValue] = useState<number>(frameNumber);
    const [form] = Form.useForm();
    const defaultAllStates = useSelector((state: CombinedState) => state.annotation.statesList.Statesdata);

    const [textmsg, setTextmsg] = useState('');
    const keyMap = useSelector((state: CombinedState) => state.shortcuts.keyMap);
    //uuid search
    const [uuidVisible, setuuidVisible] = useState(false);
    const subKeyMap = {
        SWITCH_UUID: keyMap.SWITCH_UUID,
    };
    const [Visible, setVisible] = useState(false);

    useEffect(() => {
        if (Visible) inputRefOne.current?.focus();
    }, [Visible]);

    const [selectedValue, setSelectedValue] = useState('');
    const [selecteduuid, setSelecteduuid] = useState('');
    const metaFrames = JSON.parse(localStorage.getItem('meta') ?? '{}');

    const handleClose = () => {
        setVisible(false);
        setSelectedValue('');
        form.resetFields();
        setSelecteduuid('');
        setuuidVisible(false);
    };

    useEffect(() => {
        if (frameNumber !== frameInputValue) {
            setFrameInputValue(frameNumber);
        }
    }, [frameNumber]);

    const showDeleteFrameDialog = useCallback(() => {
        if (!playing) {
            switchNavigationBlocked(true);
            modal.confirm({
                title: `Do you want to delete frame #${frameNumber}?`,
                content: 'The frame will not be visible in navigation and exported datasets, but it still can be restored with all the annotations.',
                className: 'cvat-modal-delete-frame',
                okText: 'Delete',
                okType: 'danger',
                onOk: () => {
                    switchNavigationBlocked(false);
                    onDeleteFrame();
                },
                afterClose: () => {
                    switchNavigationBlocked(false);
                },
            });
        }
    }, [playing, frameNumber]);


    const ErrorNotification = () => {
        setFrameerror(false);
        textmsg === 'Entered UUID number is not available' ? setuuidVisible(true) : setVisible(true);
    };
    function getAttrubiteValue(object: any, attributeName: string): any {
        const attributes = object?.label?.attributes || [];
        const attributesList = attributes.find((attribute: { name: string }) => attribute.name === attributeName);
        const attributePropertyKey = attributesList?.id;
        const attributeValue = object?.attributes[attributePropertyKey];
        return attributeValue;
    }
    const onSearchUUID = () => {
        let flag = false;
        const searchValue = inputRefOne?.current?.state?.value;
        for (const objectData of Object.values(defaultAllStates)) {
            if (objectData?._removed === true) {
                continue;
            }
            const uuidValue = getAttrubiteValue(objectData, 'UUID');
            if (uuidValue === searchValue) {
                flag = true;
                let Frameno = null;
                let activatedStateID: number | null = null;
                Frameno = objectData?.frame;
                activatedStateID = objectData?.clientID;
                onFrameIconSearch(Frameno);
                setTimeout(function () {
                    dispatch(activateObjectAction(activatedStateID, null, null));
                    let sidebarItem = null;
                    if (Number.isInteger(null)) {
                        sidebarItem = window.document.getElementById(
                            `cvat-objects-sidebar-state-item-element-${activatedStateID}`,
                        );
                    } else {
                        sidebarItem = window.document.getElementById(
                            `cvat-objects-sidebar-state-item-${activatedStateID}`,
                        );
                    }

                    if (sidebarItem) {
                        sidebarItem.scrollIntoView({ behavior: 'instant', block: 'start', inline: 'start' });
                    }
                }, 1000);
                break;
            }
        }
        if (!flag) {
            setFrameerror(true);
            setTextmsg('Entered UUID number is not available');
        }
        setSelecteduuid('');
        form.resetFields();
        setuuidVisible(false);
    };

    const tailLayout = {
        wrapperCol: { offset: 8, span: 16 },
    };

    const handlers = {
        SWITCH_UUID: (event: KeyboardEvent) => {
            if (event) event.preventDefault();
            dispatch(collectStatesAction(jobInstance));
            setuuidVisible(true);
        },
    };

    const handleChange = (e: any) => {
        if (!/[0-9]/.test(e.key)) {
            e.preventDefault();
        }
        setSelectedValue(e.target.value);
    };

    const onReset = () => {
        form.resetFields();
    };

    return (
        <>
            <GlobalHotKeys keyMap={subKeyMap} handlers={handlers} />
            <Modal
                autoFocus={true}
                visible={uuidVisible}
                title='Search a objects Based on UUID'
                footer={null}
                closable={false}
            >
                <Form layout='vertical' autoComplete='off' form={form} onFinish={() => onSearchUUID()}>
                    <Form.Item
                        name='UUID Number'
                        label='UUID Number'
                        rules={[
                            { required: true },
                            {
                                max: 36,
                                message: 'Value should be equal to 36 character',
                            },
                            {
                                min: 36,
                                message: 'Value should be equal to 36 character',
                            },
                        ]}
                        validateTrigger='onBlur'
                    >
                        <Input
                            autoFocus={true}
                            ref={inputRefOne}
                            id='uuid_name'
                            name='uuid_name'
                            type='text'
                            placeholder='Plese enter uuid number'
                            value={selecteduuid}
                            onChange={(e) => handleChange(e)}
                        />
                    </Form.Item>
                    <Form.Item {...tailLayout}>
                        <Button type='primary' htmlType='submit'>
                            Search
                        </Button>
                        <Button htmlType='button' onClick={onReset}>
                            Reset
                        </Button>
                        <Button htmlType='button' onClick={handleClose}>
                            Close
                        </Button>
                    </Form.Item>
                </Form>
            </Modal>
            <Col className='cvat-player-controls'>
                <Row align='bottom'>
                    <Col>
                        <Slider
                            className='cvat-player-slider'
                            min={startFrame}
                            max={stopFrame}
                            value={frameNumber || 0}
                            onChange={onSliderChange}
                        />
                    </Col>
                </Row>
                <Row justify='center'>
                    <Col className='cvat-player-filename-wrapper'>
                        <CVATTooltip title={frameFilename}>
                            <Text type='secondary'>{frameFilename}</Text>
                        </CVATTooltip>
                    </Col>
                    <Col offset={1}>
                        <CVATTooltip title='Create frame URL'>
                            <LinkOutlined className='cvat-player-frame-url-icon' onClick={onURLIconClick} />
                        </CVATTooltip>
                        {/* { (!frameDeleted) ? (
                            <CVATTooltip title={`Delete the frame ${deleteFrameShortcut}`}>
                                <DeleteOutlined className='cvat-player-delete-frame' onClick={showDeleteFrameDialog} />
                            </CVATTooltip>
                        ) : (
                            <CVATTooltip title='Restore the frame'>
                                <Icon className='cvat-player-restore-frame' onClick={onRestoreFrame} component={RestoreIcon} />
                            </CVATTooltip>
                        )} */}
                    </Col>
                </Row>
            </Col>
            <Col>
                <CVATTooltip title={`Press ${focusFrameInputShortcut} to focus here`}>
                    <InputNumber
                        ref={inputFrameRef}
                        className='cvat-player-frame-selector'
                        type='number'
                        value={frameInputValue}
                        onChange={(value: number | undefined | string | null) => {
                            if (typeof value !== 'undefined' && value !== null) {
                                setFrameInputValue(Math.floor(clamp(+value, startFrame, stopFrame)));
                            }
                        }}
                        onFocus={() => inputFrameRef.current?.select()}
                        onBlur={() => {
                            onInputChange(frameInputValue);
                        }}
                        onPressEnter={() => {
                            onInputChange(frameInputValue);
                        }}
                    />
                </CVATTooltip>
            </Col>
        </>
    );
}

export default React.memo(PlayerNavigation);
