// Copyright (C) 2021-2022 Intel Corporation
// Copyright (C) 2022 CVAT.ai Corporation
//
// SPDX-License-Identifier: MIT

import React, { useCallback } from 'react';
import Text from 'antd/lib/typography/Text';
import Collapse from 'antd/lib/collapse';
import { useSelector } from 'react-redux';

import ObjectButtonsContainer from 'containers/annotation-page/standard-workspace/objects-side-bar/object-buttons';
import ItemDetailsContainer from 'containers/annotation-page/standard-workspace/objects-side-bar/object-item-details';
import { ObjectType, ShapeType, ColorBy } from 'reducers';
import { ObjectState } from 'cvat-core-wrapper';
import ObjectItemElementComponent from './object-item-element';
import ItemBasics from './object-item-basics';

interface Props {
    points: any;
    normalizedKeyMap: Record<string, string>;
    readonly: boolean;
    activated: boolean;
    objectType: ObjectType;
    shapeType: ShapeType;
    clientID: number;
    serverID: number | undefined;
    labelID: number;
    locked: boolean;
    elements: any[];
    color: string;
    colorBy: ColorBy;
    labels: any[];
    attributes: any[];
    jobInstance: any;
    activate(activeElementID?: number): void;
    copy(): void;
    propagate(): void;
    propagateAll(): void;
    createURL(): void;
    switchOrientation(): void;
    toBackground(): void;
    toForeground(): void;
    remove(): void;
    changeLabel(label: any): void;
    changeColor(color: string): void;
    resetCuboidPerspective(): void;
    edit(): void;
    toggleSwithchEnable: boolean;
    colorAttribute: any;
}

function isClickSame(prevProps: Props, nextProps: Props): boolean {
    return (
        nextProps.points === prevProps.points &&
        nextProps.activated === nextProps.activated &&
        nextProps.labelID === prevProps.labelID
    );
}

function ObjectItemComponent(props: Props): JSX.Element {
    const {
        points,
        activated,
        readonly,
        objectType,
        shapeType,
        clientID,
        serverID,
        locked,
        labelID,
        color,
        colorBy,
        elements,
        attributes,
        labels,
        normalizedKeyMap,
        activate,
        copy,
        propagate,
        propagateAll,
        createURL,
        switchOrientation,
        toBackground,
        toForeground,
        remove,
        changeLabel,
        changeColor,
        resetCuboidPerspective,
        edit,
        jobInstance,
        toggleSwithchEnable,
        colorAttribute,
    } = props;

    const type =
        objectType === ObjectType.TAG
            ? ObjectType.TAG.toUpperCase()
            : `${shapeType.toUpperCase()} ${objectType.toUpperCase()}`;

    const className = !activated
        ? 'cvat-objects-sidebar-state-item'
        : 'cvat-objects-sidebar-state-item cvat-objects-sidebar-state-active-item';

    const activateState = useCallback(() => {
        activate();
    }, []);

    return (
        <div style={{ display: 'flex', marginBottom: '1px' }}>
            <div className='cvat-objects-sidebar-state-item-color' style={{ background: `${color}` }} />
            {!toggleSwithchEnable ? (
                <div
                    onClick={activateState}
                    id={`cvat-objects-sidebar-state-item-${clientID}`}
                    className={className}
                    style={{ backgroundColor: `${color}88` }}
                >
                    <ItemBasics
                        points={points}
                        jobInstance={jobInstance}
                        readonly={readonly}
                        serverID={serverID}
                        clientID={clientID}
                        labelID={labelID}
                        labels={labels}
                        shapeType={shapeType}
                        objectType={objectType}
                        color={color}
                        colorBy={colorBy}
                        type={type}
                        locked={locked}
                        copyShortcut={normalizedKeyMap.COPY_SHAPE}
                        pasteShortcut={normalizedKeyMap.PASTE_SHAPE}
                        propagateShortcut={normalizedKeyMap.PROPAGATE_OBJECT}
                        propagateAllShortcut={normalizedKeyMap.PROPAGATE_ALL_OBJECT}
                        toBackgroundShortcut={normalizedKeyMap.TO_BACKGROUND}
                        toForegroundShortcut={normalizedKeyMap.TO_FOREGROUND}
                        removeShortcut={normalizedKeyMap.DELETE_OBJECT}
                        changeColorShortcut={normalizedKeyMap.CHANGE_OBJECT_COLOR}
                        changeLabel={changeLabel}
                        changeColor={changeColor}
                        copy={copy}
                        remove={remove}
                        propagate={propagate}
                        propagateAll={propagateAll}
                        createURL={createURL}
                        switchOrientation={switchOrientation}
                        toBackground={toBackground}
                        toForeground={toForeground}
                        resetCuboidPerspective={resetCuboidPerspective}
                        edit={edit}
                        colorAttribute={colorAttribute}
                    />
                    <ObjectButtonsContainer readonly={readonly} clientID={clientID} />
                    {!!attributes.length && (
                        <ItemDetailsContainer readonly={readonly} clientID={clientID} parentID={null} />
                    )}
                    {!!elements.length && (
                        <>
                            <Collapse className='cvat-objects-sidebar-state-item-elements-collapse'>
                                <Collapse.Panel
                                    header={
                                        <>
                                            <Text style={{ fontSize: 10 }} type='secondary'>
                                                PARTS
                                            </Text>
                                            <br />
                                        </>
                                    }
                                    key='elements'
                                >
                                    {elements.map((element: ObjectState) => (
                                        <ObjectItemElementComponent
                                            key={element.clientID as number}
                                            readonly={readonly}
                                            parentID={clientID}
                                            clientID={element.clientID as number}
                                            onMouseLeave={activateState}
                                        />
                                    ))}
                                </Collapse.Panel>
                            </Collapse>
                        </>
                    )}
                </div>
            ) : (
                <div
                    onMouseEnter={activateState}
                    id={`cvat-objects-sidebar-state-item-${clientID}`}
                    className={className}
                    style={{ backgroundColor: `${color}88` }}
                >
                    <ItemBasics
                        jobInstance={jobInstance}
                        points={points}
                        readonly={readonly}
                        serverID={serverID}
                        clientID={clientID}
                        labelID={labelID}
                        labels={labels}
                        shapeType={shapeType}
                        objectType={objectType}
                        color={color}
                        colorBy={colorBy}
                        type={type}
                        locked={locked}
                        copyShortcut={normalizedKeyMap.COPY_SHAPE}
                        pasteShortcut={normalizedKeyMap.PASTE_SHAPE}
                        propagateShortcut={normalizedKeyMap.PROPAGATE_OBJECT}
                        propagateAllShortcut={normalizedKeyMap.PROPAGATE_ALL_OBJECT}
                        toBackgroundShortcut={normalizedKeyMap.TO_BACKGROUND}
                        toForegroundShortcut={normalizedKeyMap.TO_FOREGROUND}
                        removeShortcut={normalizedKeyMap.DELETE_OBJECT}
                        changeColorShortcut={normalizedKeyMap.CHANGE_OBJECT_COLOR}
                        changeLabel={changeLabel}
                        changeColor={changeColor}
                        copy={copy}
                        remove={remove}
                        propagate={propagate}
                        propagateAll={propagateAll}
                        createURL={createURL}
                        switchOrientation={switchOrientation}
                        toBackground={toBackground}
                        toForeground={toForeground}
                        resetCuboidPerspective={resetCuboidPerspective}
                        edit={edit}
                        colorAttribute={colorAttribute}
                    />
                    <ObjectButtonsContainer readonly={readonly} clientID={clientID} />
                    {!!attributes.length && (
                        <ItemDetailsContainer readonly={readonly} clientID={clientID} parentID={null} />
                    )}
                    {!!elements.length && (
                        <>
                            <Collapse className='cvat-objects-sidebar-state-item-elements-collapse'>
                                <Collapse.Panel
                                    header={
                                        <>
                                            <Text style={{ fontSize: 10 }} type='secondary'>
                                                PARTS
                                            </Text>
                                            <br />
                                        </>
                                    }
                                    key='elements'
                                >
                                    {elements.map((element: ObjectState) => (
                                        <ObjectItemElementComponent
                                            key={element.clientID as number}
                                            readonly={readonly}
                                            parentID={clientID}
                                            clientID={element.clientID as number}
                                            onMouseLeave={activateState}
                                        />
                                    ))}
                                </Collapse.Panel>
                            </Collapse>
                        </>
                    )}
                </div>
            )}
        </div>
    );
}

export default React.memo(ObjectItemComponent, isClickSame);
