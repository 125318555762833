import { ObjectState } from 'cvat-core-wrapper';
import { ColorBy } from 'reducers';

import colorAttributes from '../../../../../../cvat-canvas3d/src/typescript/colors';

export function getColor(state: ObjectState, colorBy: ColorBy, colorAttribute: any): string {
    let color = '';
    const staticAttributesColor = colorAttributes.filter((attr) => attr.name === colorAttribute).map((attr) => attr);
    if (colorBy === ColorBy.INSTANCE) {
        color = state.color;
    } else if (colorBy === ColorBy.GROUP) {
        color = state.group?.color || '#000';
    } else if (colorBy === ColorBy.LABEL) {
        color = state.label.color as string;
    } else if (colorBy === ColorBy.ATTRIBUTE) {
        let newObjProperties: any;
        const attributeOriginal = state.label.attributes.filter((elem) => staticAttributesColor.some((ele) =>  ele.name === elem.name));
        const attr = [];
        attr.push(state?.attributes);
        attr.filter((attribute) => {
            return Object.keys(attribute).map((item) => {
                attributeOriginal.some((ele) => {
                    if (ele.id == item) {
                        newObjProperties = attribute[item];
                    }
                });
            });
        });
        const staticAttributeValues = staticAttributesColor.map((el) => el.values);
        let SelectedAttributeColor: any;
        staticAttributeValues.map((attributeVal) => {
            return Object.keys(attributeVal).map((item) => {
                return Object.entries(attributeVal[item]).map(([key, value]) => {
                    if (key === newObjProperties) {
                        SelectedAttributeColor = value;
                    }
                });
            });
        });
        color = SelectedAttributeColor as string || state.label.color as string;
    }

    return color;
}
